import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { DragDropContext } from 'react-beautiful-dnd';

import Column from './Column';
import {
  CandidateInfo,
  InterviewRoom,
  InterviewStage,
  InterviewTask,
  CandidatesStagesStatuses,
} from '../../../types';
import { groupByStages, GroupByStagesFilters } from '../../../utils/candidate';
import InterviewStageModal from './InterviewStageModal';
import {
  getClientInterviewStages,
  getInterview,
  moveToNextTask,
} from '../../../api/interviews';
import InterviewDateProposeModal from './InterviewDateProposeModal';
import FeedbackModal from './FeedbackModal';
import axios from 'axios';
import CandidateOfferModal from '../CandidateOfferModal';
import { ReactComponent as EmptyIcon } from '../../../icons/empty.svg';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import Loader from '../../../UI/Loader';
import { COMPLETED, INTRODUCED, OFFERED, ON_HOLD, REJECTED, SOURCED } from '../../../constants/statuses';
import { useAppSelector } from '../../../store/hooks';
import {
  CLIENT_ROLE,
  ADMIN_ROLE,
  RECRUITER_ROLE,
} from '../../../constants/roles';
import { getJobsInterviewStages } from '../../../api/interview-stages';
import ConfirmModal from './ConfirmModal';
import {
  INTERVIEW_ID_PARAM,
  STAGE_PARAM,
  SHOW_DATE_PROPOSE_PARAM,
  SHOW_FEEDBACK_PARAM,
  SHOW_COMPANY_CHAT,
} from '../../../constants/queryParams';
import { HIRED } from '../../../constants/statuses';
import CompanyChatModal from './CompanyChatModal';
import { getCandidatesStagesStatuses } from '../../../api/candidates';
import MoveInAtsModal from './MoveInAtsModal';
import { getDisallowedColumns } from './getDisallowedColumns';
import KanbanFilter from './KanbanFilter';

interface KanbanProps {
  candidates: CandidateInfo[];
  setInfo: React.Dispatch<React.SetStateAction<CandidateInfo | null>>;
  collect: (info: CandidateInfo[]) => CandidateInfo[];
}

const KanbanContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  position: relative;
`;

const FilterContainer = styled.div`
  margin-bottom: 1rem;
  position: sticky;
  left: 0;
  right: 0;
  z-index: 10;
  background-color: inherit;
  width: 100%;
`;

const Wrapper = styled.div`
  display: flex;
  flex: 1 1 auto;
  overflow-x: auto;
`;
const Empty = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  p {
    font-size: 0.875rem;
    line-height: 1.43;
    text-align: center;
    color: #aebeca;
    margin: 0;
  }
  svg {
    margin: 0 auto 1rem;
    display: block;
  }
`;

type JobStages = {
  job_id: string;
  stages: {
    id: string;
    name: string;
    order: number;
  }[];
}

const Kanban = ({ candidates, setInfo, collect }: KanbanProps) => {
  const { user } = useAppSelector((state) => state.user);
  const [empty, setEmpty] = useState(false);
  const [loading, setLoading] = useState(true);
  const [interviewStages, setInterviewStages] = useState<InterviewStage[] | null>(null);
  const [jobStages, setJobStages] = useState<JobStages[]>([]);
  const [disabled, setDisabled] = useState<Record<string, boolean>>({});
  const [interview, setInterview] = useState<InterviewRoom | null>(null);
  const [candidateStagesStatuses, setCandidateStagesStatuses] = useState<
    CandidatesStagesStatuses[]
  >([]);

  const [nextStageData, setNextStageData] = useState<{
    interviewId: string;
    candidateID: string;
    stage_name?: string;
    from: string;
    stage_id?: string;
    reason_for_change?: string;
    is_rejection?: boolean;
  } | null>(null);

  const [candidateStages, setCandidateStages] =
    useState<Record<string, CandidateInfo[]> | null>(null);

  const [showInterviewStageModal, setShowInterviewStageModal] = useState(false);
  const [showCompanyChatModal, setShowCompanyChatModal] = useState(false);

  const [t] = useTranslation();
  const history = useHistory();
  const isClient = user?.role === CLIENT_ROLE;
  const isRecruiter = user?.role === RECRUITER_ROLE;
  const params = useMemo(() => new URLSearchParams(history.location.search), [history.location.search]);

  const interview_id = params.get(INTERVIEW_ID_PARAM);
  const stage = params.get(STAGE_PARAM); // use this for the current stage
  const show_date_propose = params.get(SHOW_DATE_PROPOSE_PARAM);
  const feedback = params.get(SHOW_FEEDBACK_PARAM);
  const chat = params.get(SHOW_COMPANY_CHAT);

  /* get, sort and set stages start */
  useEffect(() => {
    const source = axios.CancelToken.source();
    getClientInterviewStages().then((res) => {
      const sorted = res.data.sort(
        (a: InterviewStage, b: InterviewStage) => a.order - b.order,
      );
      setInterviewStages(sorted);
      // the order of the keys in this object are significant!
      const keys: Record<string, boolean> = {
        [SOURCED]: false,
        [ON_HOLD]: false,
        [INTRODUCED]: false,
        ...Object.fromEntries(sorted.map((s: InterviewStage) => [s.name.toLowerCase(), false])),
        [OFFERED]: false,
        [HIRED]: false,
      };
      setDisabled(keys);
    });
    return () => source.cancel();
  }, []);

  useEffect(() => {
    if (interviewStages) {
      const candidateStageGroup = Object.fromEntries(interviewStages.map((interviewStage) => [interviewStage.name.toLowerCase(), []]));
      const showReject = user?.role === CLIENT_ROLE;
      const showHired = user?.role !== ADMIN_ROLE;
      const sourcedStageEnabled = !!user?.sourcing_stage_enabled;
      const filters: GroupByStagesFilters = {
        jobTitle: params.get('job_title'),
        companyName: params.get('company_name'),
        lastUpdateBefore: params.get('last_update_before'),
        lastUpdateAfter: params.get('last_update_after'),
      }
      groupByStages(
        candidates,
        candidateStageGroup,
        sourcedStageEnabled,
        showReject,
        showHired,
        filters,
      ).then((res) => {
        setLoading(false);
        if (user?.role === ADMIN_ROLE) {
          // clear up default empty hired array
          const { hired, ...candidateStages } = res;
          setCandidateStages(candidateStages);
          return;
        }
        setCandidateStages(res);
      });
    }
    return () => setLoading(true);
  }, [candidates, interviewStages, user?.role, params, user?.sourcing_stage_enabled]);
  /* get, sort and set stages end */

  useEffect(() => {
    const getInterviewById = async (id: string) => {
      const response = await getInterview(id, history);
      return response.data;
    };

    if (candidateStages) {
      if (interview_id) {
        getInterviewById(interview_id).then((res) => {
          const mainInterview = res.current_stage?.tasks?.find(
            (task: InterviewTask) => task.is_planning_task,
          );

          if (mainInterview?.status === COMPLETED) {
            setInterview({
              ...res,
              next_stage: stage
                ? candidateStages[stage]?.find(
                  (el) => el.interviews[0].id === interview_id,
                )?.interviews[0].next_stage
                : null,
            });
          } else {
            setInterview(res);
          }
          if (stage) {
            setShowInterviewStageModal(true);
          }
          if (chat) {
            setShowCompanyChatModal(true);
          }
        });
      } else {
        setInterview(null);
      }
    } else {
      setInterview(null);
    }
  }, [
    candidateStages,
    interview_id,
    stage,
    history,
    chat,
    user?.sourcing_stage_enabled
  ]);

  useEffect(() => {
    if (isClient || isRecruiter)
      getJobsInterviewStages().then((res) => setJobStages(res.data));
  }, [isClient, isRecruiter]);

  useEffect(() => {
    getCandidatesStagesStatuses().then((res) =>
      setCandidateStagesStatuses(res.data.results),
    );
  }, []);

  useEffect(() => {
    if (candidates.length === 0 && candidates.length > 0) {
      setEmpty(true);
      setLoading(false);
      return;
    }
    setEmpty(false);
  }, [candidates, isClient, candidates.length]);


  const setAllowedColumns = (currentStageName: string, jobId: string, recruiterCanMoveCandidates = true) => {
    const allKanbanColumns = Object.keys(disabled);
    const allowedStages = jobStages.find((el) => el.job_id === jobId);
    const sortedStages = allowedStages?.stages.sort(
      (a, b) => a.order - b.order,
    );
    const userIsSourcer = (isRecruiter && user?.is_sourcer) ?? null;

    const jobHiringProcessInterviewStageNames = sortedStages?.map((st) => st.name) || [];

    const disallowedColumns = getDisallowedColumns(
      allKanbanColumns,
      interviewStages,
      jobHiringProcessInterviewStageNames,
      currentStageName,
      userIsSourcer,
      isRecruiter,
      recruiterCanMoveCandidates,
    );
    setDisabled(disallowedColumns);
  };

  const disallowAllColumns = () => {
    let disabledFields: Record<string, boolean> = {};
    Object.keys(disabled).forEach((stage) => {
      disabledFields[stage] = false;
    });
    setDisabled(disabledFields);
  };

  const onDragEnd = (result: any) => {
    if (result?.destination) {
      const dropColumn = result?.destination?.droppableId;
      const isSame = dropColumn === result?.source?.droppableId;

      if (candidateStages && !isSame) {
        const dragColumn = result.source.droppableId.toLowerCase();
        const dragIndex = result.source.index;
        const hasUncompletedTasks = !!candidateStages[dragColumn][
          dragIndex
        ].interviews[0].current_stage?.tasks?.filter(
          (el) => el.status !== 'completed',
        ).length;
        const isRejection = dropColumn === REJECTED;
        const dynamicStage = interviewStages?.find(
          (el) => el.name.toLowerCase() === dropColumn,
        );
        const dynamicStageId = dynamicStage?.id;
        const dragItem = candidateStages[dragColumn][dragIndex];
        if (isRejection) {
          setNextStageData({
            interviewId: dragItem.interviews[0].id,
            candidateID: dragItem.id,
            stage_name: dropColumn,
            reason_for_change: '',
            from: dragColumn,
            stage_id: dynamicStageId,
            is_rejection: true,
          });
        } else if (hasUncompletedTasks) {
          setNextStageData({
            interviewId: dragItem.interviews[0].id,
            candidateID: dragItem.id,
            stage_name: dropColumn,
            from: dragColumn,
            stage_id: dynamicStageId,
          });
        } else {
          moveTo(
            dragItem?.interviews[0].id,
            dragItem?.id,
            dropColumn,
            dragColumn,
          );
        }
      }
    }
    disallowAllColumns();
  };

  const onDragStart = (result: any) => {
    const column = result.source.droppableId.toLowerCase();
    const idx = result.source.index;
    const jobId =
      (candidateStages && candidateStages[column][idx].interviews[0].job.id) ||
      '';
    setAllowedColumns(column, jobId, candidateStages?.[column][idx].interviews[0].job.company.recruiters_can_move_candidates ?? true);
  };

  const updateInterview = (
    interviewId: string,
    stageName: string,
    candidateID: string,
  ) => {
    getInterview(interviewId, history).then((res) => {
      candidateStages &&
        setCandidateStages((prevState) => {
          if (prevState) {
            const keys = Object.keys(prevState);
            let update: { [key: string]: CandidateInfo[] } = {};
            keys.forEach((el) => {
              if (el === stageName) {
                update[el] = prevState[stageName].map((el) => {
                  if (el.interviews[0].id === interviewId) {
                    let interview = el.interviews;
                    interview[0] = { ...interview[0], ...res.data };
                    return { ...el, interviews: [...interview] };
                  }
                  return el;
                });
              } else {
                update[el] = prevState[el];
              }
            });
            return update;
          } else {
            return null;
          }
        });
    });
  };

  const removeItem = (
    candidateID: string,
    currentStage: string,
    interviewId: string,
  ) => {
    if (candidateStages) {
      setCandidateStages((prevState) => ({
        ...prevState,
        [currentStage]: prevState
          ? prevState[currentStage].filter(
            (el) =>
              el.id !== candidateID && el.interviews[0].id !== interviewId,
          )
          : [],
      }));
    }
  };

  const moveTo = async (
    interviewId: string,
    candidateId: string,
    nextStageNameRaw: string,
    currentStageNameRaw: string,
    reason?: string,
  ) => {
    const nextStageName = nextStageNameRaw.toLowerCase();
    const currentStageName = currentStageNameRaw.toLowerCase();

    const nextStage = interviewStages?.find(
      (el) => el.name.toLowerCase() === nextStageName,
    );
    const nextStageId = nextStage?.id;

    await moveToNextTask(interviewId, nextStageName, nextStageId, reason).then(
      (_) => {
        getInterview(interviewId, history).then((response) => {
          if (candidateStages) {
            const target = candidateStages[currentStageName]?.find(
              (card) =>
                card.id === candidateId &&
                card.interviews[0].id === interviewId,
            );

            if (target) {
              // @ts-ignore
              setCandidateStages((prevState) => {
                if (!prevState) return null;

                // if the target stage doesn't exist here - probably because we're a recruiter and are moving to rejected
                if (!prevState[nextStageName])
                  return {
                    ...prevState,
                    [currentStageName]: prevState[currentStageName].filter(
                      // just remove the record
                      (card) => card.interviews[0].id !== interviewId,
                    ),
                  };

                // if we are a client user, temporarily mask the blocked flag since it should be corrected soon by the ATS processing tasks
                const newInterview = isClient
                  ? { ...response.data, is_blocked: false }
                  : response.data;

                const newCard = {
                  ...target,
                  interviews: [newInterview, ...target.interviews.slice(1)],
                };
                if (currentStageName === nextStageName) {
                  return {
                    ...prevState,
                    [currentStageName]: prevState[currentStageName].map(
                      (card) =>
                        card.interviews[0].id === interviewId ? newCard : card,
                    ),
                  };
                } else {
                  return {
                    ...prevState,
                    [currentStageName]: prevState[currentStageName].filter(
                      (card) => card.interviews[0].id !== interviewId,
                    ),
                    [nextStageName]: [newCard, ...prevState[nextStageName]],
                  };
                }
              });
            }
          }
          setNextStageData(null);

          if (stage) {
            const query = new URLSearchParams(history.location.search);
            query.set(STAGE_PARAM, nextStageName);
            history.push({ search: query.toString() });
          }

          if (nextStageName === REJECTED) {
            // close all modals
            history.push({ search: '?filter=in-process' });
          }
        });
      },
    );
  };

  const moveToNextStage = (
    interviewId: string,
    candidateId: string,
    nextStageNameRaw: string,
    currentStageNameRaw: string,
    hasUncompletedTasks: boolean,
  ) => {
    const nextStageName = nextStageNameRaw.toLowerCase();
    const currentStageName = currentStageNameRaw.toLowerCase();

    const nextStage = interviewStages?.find(
      (el) => el.name.toLowerCase() === nextStageName,
    );

    const nextStageId = nextStage?.id;

    if (hasUncompletedTasks) {
      setNextStageData({
        interviewId: interviewId,
        candidateID: candidateId,
        stage_name: nextStageName,
        from: currentStageName,
        stage_id: nextStageId,
      });
    } else {
      moveTo(interviewId, candidateId, nextStageName, currentStageName);
    }
  };

  const [moveInAtsModalState, setMoveInAtsModalState] =
    useState<CandidateInfo | null>(null);

  return (
    <KanbanContainer>
      <FilterContainer>
        <KanbanFilter />
      </FilterContainer>
      <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
        {loading ? (
          <Loader spinning={loading}></Loader>
        ) : (
          <Wrapper>
            {candidateStages &&
              Object.keys(candidateStages).map((group) => (
                <Column
                  title={group}
                  data={candidateStages[group]}
                  key={group}
                  disabled={disabled[group]}
                  candidateStagesStatuses={candidateStagesStatuses}
                  onOffSyncClicked={(info) => {
                    isClient &&
                      !info.interviews?.[0].ats_error &&
                      setMoveInAtsModalState(info);
                  }}
                />
              ))}

            {empty && (
              <Empty>
                <EmptyIcon />
                <p>{t('NO_CANDIDATE_YET')}</p>
              </Empty>
            )}
          </Wrapper>
        )}

        <InterviewStageModal
          interview={interview || {}}
          visible={showInterviewStageModal && !!interview_id && !!stage}
          setShowInterviewStageModal={setShowInterviewStageModal}
          handleSwitchStage={moveToNextStage}
          handleEndProcess={setNextStageData}
          currentStage={stage || ''}
        />

        <CompanyChatModal
          interview={interview || {}}
          visible={showCompanyChatModal && !!interview_id}
          setShowCompanyChatModal={setShowCompanyChatModal}
        />

        {show_date_propose && interview && (
          <InterviewDateProposeModal
            info={interview}
            onComplete={updateInterview}
          />
        )}

        {feedback && interview && (
          <FeedbackModal
            info={interview}
            onComplete={updateInterview}
            removeItem={removeItem}
            setShowInterviewStageModal={setShowInterviewStageModal}
          />
        )}

        <CandidateOfferModal
          collect={collect}
          onSuccess={updateInterview}
          removeItem={removeItem}
          setShowInterviewStageModal={setShowInterviewStageModal}
        />

        <ConfirmModal
          stage={nextStageData}
          setStage={setNextStageData}
          move={moveTo}
          currentStage={stage || undefined}
        />

        <MoveInAtsModal
          state={moveInAtsModalState}
          onCancel={() => {
            setMoveInAtsModalState(null);
          }}
          onConfirm={async (info) => {
            const interview = info.interviews[0];
            await moveTo(
              interview.id,
              info.id,
              interview.current_stage.stage_name,
              interview.current_stage.stage_name,
            );
            setMoveInAtsModalState(null);
          }}
        />
      </DragDropContext>
    </KanbanContainer>
  );
};

export default Kanban;
