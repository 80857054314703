import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Form, message } from 'antd';
import Button from '../UI/buttons/Button';
import FormTitle from '../components/auth/FormTitle';
import SettingsMenu from '../components/settings/SettingsMenu';
import FormItem from '../components/form/FormItem';
import CheckboxInput from '../UI/inputs/CheckboxInput';
import {
  getNotificationSettingList,
  updateNotificationSettingList,
} from '../api/notifications';
import { NotificationSettings } from '../types';
import Loader from '../UI/Loader';
import { useAppSelector } from '../store/hooks';
import { RECRUITER_ROLE } from '../constants/roles';
import ReactGa from 'react-ga4';

const PageWrapper = styled.section`
  background: ${({ theme }) => theme.lozengeColor};
  width: 100%;
  display: flex;
  min-height: 100vh;
  flex-flow: row-reverse;
`;
const FormWrapper = styled.div`
  background: #fff;
  width: 61%;
  padding: 4.5rem 4rem;
  display: flex;
  flex-direction: column;
  position: relative;

  @media (max-width: 1200px) {
    width: 70%;
  }
  @media (max-width: 768px) {
    width: 90%;
    padding: 3rem;
  }
`;

const StyledForm = styled(Form)`
  margin-top: 2.5rem;
  width: 100%;
`;

const NotificationsSettings = () => {
  const [notifications, setNotifications] =
    useState<NotificationSettings | null>(null);
  const { user } = useAppSelector((state) => state.user);
  const isRecruiter = user?.role === RECRUITER_ROLE;
  const [loading, setLoading] = useState(true);
  const [loadSave, setLoadSave] = useState(false);
  const [form] = Form.useForm();
  const { t } = useTranslation();
  useEffect(() => {
    ReactGa.pageview('/notifications-settings');
  }, []);
  useEffect(() => {
    getNotificationSettingList().then((res) => {
      const { data } = res;

      setNotifications({
        dates_proposed: data.dates_proposed,
        date_confirmed: data.date_confirmed,
        feedback_required: data.feedback_required,
        feedback_received: data.feedback_received,
        new_candidate: data.new_candidate,
        new_unread_message: data.new_unread_message,
        introduced_candidate_invite: data.introduced_candidate_invite,
        introduced_candidate_reject: data.introduced_candidate_reject,
        offer_confirm: data.offer_confirm,
        offer_disagree: data.offer_disagree,
        offer_make: data.offer_make,
        offer_reject: data.offer_reject,
        preference_relevance_interval: data.preference_relevance_interval,
        job_activity_feed: data.job_activity_feed,
      });
      setLoading(false);
    });
  }, [form]);
  const checkIfSwitchedOff = useCallback(() => {
    for (var key in notifications) {
      // @ts-ignore
      if (notifications[key]) return false;
    }
    return true;
  }, [notifications]);

  const onFinish = (values: any) => {
    setLoadSave(true);
    updateNotificationSettingList(values)
      .then((res) => {
        setLoadSave(false);
        message.success(t('SETTINGS_SAVED'), 5);
      })
      .catch((e) => {
        setLoadSave(false);
        message.error(t(e.response?.data?.message), 5);
      });
  };
  const switchOff = (status: boolean) => {
    if (notifications) {
      let switched: NotificationSettings = { ...notifications };
      if (status) {
        Object.keys(notifications).forEach((el: string) => {
          // @ts-ignore
          switched[el] = false;
        });
        setNotifications(switched);
      } else {
        Object.keys(notifications).forEach((el: string) => {
          // @ts-ignore
          switched[el] = true;
        });
        setNotifications(switched);
        form.setFieldsValue({ off: false });
      }
    }
  };
  useEffect(() => {
    if (checkIfSwitchedOff()) {
      form.setFieldsValue({ off: true });
    } else {
      form.setFieldsValue({ off: false });
    }
    form.setFieldsValue(notifications);
  }, [notifications, form, checkIfSwitchedOff]);
  return (
    <PageWrapper>
      <FormWrapper>
        <SettingsMenu menu="notification" />
        <StyledForm
          scrollToFirstError={true}
          layout="vertical"
          name="invitation"
          onFinish={onFinish}
          form={form}
        >
          <FormTitle text={t('NOTIFICATION_SETTINGS')} />

          <Loader spinning={loading}>
            <FormItem valuePropName="checked" name={'off'} initialValue={false}>
              <CheckboxInput
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  switchOff(e.target.checked);
                }}
              >
                {t('SWITCH_OFF_ALL')}
              </CheckboxInput>
            </FormItem>
            {notifications &&
              Object.keys(notifications).map((notification, index) => {
                return isRecruiter &&
                  notification === 'new_candidate' ? null : (
                  <FormItem
                    name={notification}
                    valuePropName="checked"
                    key={notification}
                  >
                    <CheckboxInput
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        if (e.target.checked) {
                          setNotifications(
                            (prevState) =>
                              prevState && {
                                ...prevState,
                                [notification]: e.target.checked,
                                introduced_candidate_invite: true,
                                introduced_candidate_reject: true,
                                offer_confirm: true,
                                offer_disagree: true,
                                offer_make: true,
                                offer_reject: true,
                                preference_relevance_interval: true,
                              },
                          );
                        } else {
                          setNotifications(
                            (prevState) =>
                              prevState && {
                                ...prevState,
                                [notification]: e.target.checked,
                              },
                          );
                        }
                      }}
                    >
                      {t(notification.toUpperCase())}
                    </CheckboxInput>
                  </FormItem>
                );
              })}
            {/*      <FormItem>
            <CheckboxInput>{t('RECEIVE_PLATFORM_UPDATES')}</CheckboxInput>
          </FormItem>

          <FormItem>
            <CheckboxInput>{t('RECEIVE_NEWSLETTER')}</CheckboxInput>
          </FormItem>

          <FormItem>
            <CheckboxInput>{t('RECEIVE_NEW_JOBS')}</CheckboxInput>
          </FormItem>

          <FormItem>
            <CheckboxInput>{t('RECEIVE_EMAIL_CHAT')}</CheckboxInput>
          </FormItem>*/}

            <FormItem>
              <Button type="primary" htmlType="submit" loading={loadSave}>
                {t('SAVE_TITLE')}
              </Button>
            </FormItem>
          </Loader>
        </StyledForm>
      </FormWrapper>
    </PageWrapper>
  );
};

export default NotificationsSettings;
