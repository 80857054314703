import React, { useCallback, useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { getJobs, getJobsByFilter } from '../api/jobs';
import PageWrapper from '../components/layout/PageWrapper';
import Header from '../components/layout/Header';
import { useAppSelector } from '../store/hooks';
import { ADMIN_ROLE, CLIENT_ROLE, RECRUITER_ROLE } from '../constants/roles';
import AdminJobs from '../components/admin/AdminJobs';
import ClientJobs from '../components/client/ClientJobs';
import RecruiterJobs from '../components/recruiter/RecruiterJobs';
import RecruiterHeaderFilters from '../components/jobs/jobList/RecruiterHeaderFilters';
import JobListFilter from '../components/jobs/jobList/JobListFilter';
import AdminHeaderFilters from '../components/jobs/jobList/AdminHeaderFilters';
import { getUrlString } from '../constants/queryParams';
import Loader from '../UI/Loader';
import { Job, Language } from '../types';
import ReactGa from 'react-ga4';
import { getLanguagesList } from '../api/languages';

const JobList = styled.div`
  padding: 0 2rem;
  background: #ffffff;
  border-radius: ${({ theme }) => theme.radius};
  @media (max-width: 576px) {
    padding: 0 1rem;
  }
`;
const NoResultText = styled.p`
  font-size: 0.75rem;
  font-weight: 700;
  line-height: 1.33;
  letter-spacing: 0.96px;
  text-align: center;
  color: #061c2e;
  padding: 1rem;
`;
const Jobs = () => {
  const [jobs, setJobs] = useState<Job[]>([]);
  const [languages, setLanguages] = useState<Language[]>([]);
  const [nextPage, setNextPage] = useState(null);
  const [loading, setLoading] = useState(true);
  const history = useHistory();
  const { user } = useAppSelector((state) => state.user);

  const isAdmin = user?.role === ADMIN_ROLE;
  const isClient = user?.role === CLIENT_ROLE;
  const isRecruiter = user?.role === RECRUITER_ROLE;
  const isClientQueryOrder =
    user?.role === CLIENT_ROLE ? '&client_order=true' : '';

  const [t] = useTranslation();
  const loader = useRef(null);
  const query = new URLSearchParams(history.location.search);

  const filter = query.get('filter');
  const domain = query.get('domain');
  const company = query.get('company');
  const manager = query.get('manager');
  const seniority = query.get('seniority');
  const location = query.get('location');
  const skill = query.get('skill');
  const search = query.get('search');
  const salary_from = query.get('salary_from');
  const salary_to = query.get('salary_to');
  const status = query.get('status');
  const company_name = query.get('company_name');
  const visa = query.get('visa');
  const language = query.get('language');
  const relocation = query.get('relocation');
  const working_type = query.get('working_type');
  const job_status = query.get('job_status');

  const handleFilterChange = useCallback(
    (filter: string, nextPage?: string | null) => {
      const query = getUrlString({
        skill,
        company,
        domain,
        seniority,
        manager,
        location,
        search,
        salary_from,
        salary_to,
        status,
        company_name,
        visa,
        language,
        relocation,
        working_type,
        job_status,
      });
      setLoading(true);
      let queryString = query && `?${(!filter ? 'favorite=true' : '') + query}`;
      if (nextPage) {
        if (query) {
          queryString += `&cursor=${nextPage}`;
        } else {
          queryString += `?cursor=${nextPage}`;
        }
      }
      getJobsByFilter(filter, queryString + isClientQueryOrder).then((res) => {
        if (nextPage) {
          setJobs((j) => [...j, ...res.data.results]);
        } else {
          setJobs(res.data.results);
        }
        setLoading(false);
        setNextPage(res.data.pagination.next);
      });
    },
    [
      skill,
      domain,
      seniority,
      location,
      salary_from,
      salary_to,
      status,
      company,
      manager,
      company_name,
      visa,
      language,
      relocation,
      working_type,
      isClientQueryOrder,
      job_status,
      search,
    ],
  );

  useEffect(() => {
    setLoading(true);
    if (filter) {
      handleFilterChange(filter);
    } else {
      getJobs(
        history.location.search
          ? history.location.search + '&favorite=true'
          : '?favorite=true' + isClientQueryOrder,
      ).then((res) => {
        setJobs(res.data.results);
        setLoading(false);
        setNextPage(res.data.pagination.next);
      });
    }
  }, [history, handleFilterChange, filter, isClientQueryOrder]);

  useEffect(() => {
    getLanguagesList().then((res) => setLanguages(res.data));
  }, []);

  const handleObserver = useCallback(
    (entities: any) => {
      const cursor = `${history.location.search
        ? history.location.search +
        (!filter ? '&favorite=true' : '') +
        '&cursor=' +
        nextPage
        : `?${!filter ? 'favorite=true&' : ''}cursor=` + nextPage
        }`;

      const target = entities[0];

      if (target.isIntersecting && nextPage) {
        if (filter) {
          handleFilterChange(filter, nextPage);
        } else {
          getJobs(cursor + isClientQueryOrder).then((res) => {
            // @ts-ignore
            setJobs((j) => [...j, ...res.data.results]);
            setNextPage(res.data.pagination.next);
          });
        }
      }
    },
    [
      nextPage,
      history.location.search,
      handleFilterChange,
      filter,
      isClientQueryOrder,
    ],
  );

  useEffect(() => {
    const current = loader.current;
    let options = {
      root: null,
      rootMargin: '0px 0px 300px 0px',
      threshold: 1.0,
    };
    const observer = new IntersectionObserver(handleObserver, options);

    if (!nextPage) {
      observer.disconnect();
    } else {
      if (loader && current) {
        observer.observe(current as unknown as Element);
      }
    }
    return () => observer.unobserve(current as unknown as Element);
  }, [nextPage, handleObserver]);

  useEffect(() => {
    ReactGa.pageview('/jobs');
  }, []);

  return (
    <PageWrapper>
      <Header title={t('JOBS')}>
        {isRecruiter && (
          <RecruiterHeaderFilters onChange={handleFilterChange} />
        )}
        {isAdmin && <AdminHeaderFilters />}
        <span></span>
      </Header>
      <JobListFilter />
      <Loader spinning={loading}>
        <JobList>
          {isAdmin && (
            <AdminJobs jobs={jobs} setJobs={setJobs} languages={languages} />
          )}
          {isClient && (
            <ClientJobs jobs={jobs} setJobs={setJobs} languages={languages} />
          )}
          {isRecruiter && (
            <RecruiterJobs
              jobs={jobs}
              setJobs={setJobs}
              languages={languages}
            />
          )}
          {!loading && !jobs.length ? (
            <NoResultText>{t('NO_RESULT')}</NoResultText>
          ) : (
            ''
          )}
        </JobList>
        <div className="loader" ref={loader} />
      </Loader>
    </PageWrapper>
  );
};

export default Jobs;
