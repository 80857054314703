import { useState } from 'react';
import { Col, Row, Select } from 'antd';
import FormItem from './FormItem';
import { ReactComponent as ArrowDownIcon } from '../../icons/arrow-down.svg';
import { ReactComponent as InfoIcon } from '../../icons/info-icon-rounded.svg';
import { useTranslation } from 'react-i18next';
import { City, Seniority, Skill, Specialization } from '../../types';
import NumberInput from '../../UI/buttons/NumberInput';
import Input from '../../UI/inputs/Input';
import SelectInput from '../../UI/inputs/Select';
import { dictionary } from '../../constants/dictionary';
import TreeSelectInput from '../../UI/inputs/TreeSelect';
import { relocationOptionsFilters } from '../../constants/relocations';

interface JobFilterProps {
  show: boolean;
  data: {
    domain: Specialization[];
    seniority: Seniority[];
    cities: City[];
    company_name: [];
    visa: boolean[];
    language: [];
    relocation: string;
    skills: Skill[];
    salary_from: null | string;
    salary_to: null | string;
    logical: null | string;
    not: boolean;
  };
  isClient?: boolean;
  isRecruiter?: boolean;
}

const { Option } = Select;
const JobFilter = ({ show, data, isClient, isRecruiter }: JobFilterProps) => {
  const [t] = useTranslation();

  // recruiter view
  let xlColSpan1 = 4;
  let xlColSpan2 = 5;
  let xlColSpan3 = 4;
  let xlColSpan4 = 8;
  let xlColSpan5 = 4;

  // client view
  if (isClient) {
    xlColSpan1 = 6;
    xlColSpan2 = 6;
    xlColSpan3 = 6;
    xlColSpan4 = 6;
  }

  // admin view
  if (show) {
    xlColSpan1 = 6;
    xlColSpan2 = 6;
    xlColSpan3 = 6;
    xlColSpan4 = 12;
    xlColSpan5 = 6;
  }

  const structuredDomains = () => {
    let structured: any = [];
    let originalDomains = data.domain;
    let domains = [...originalDomains];
    let initialDomainIds = [...originalDomains].map(
      (specialization) => specialization.id,
    );

    function filterDomains(domains: any, subDomain: any) {
      if (subDomain.sub_domains.length) {
        for (let sub of subDomain.sub_domains) {
          domains = filterDomains(domains, sub);
        }
      }
      return domains.filter((domain: any) => domain.id !== subDomain.id);
    }

    for (let domain of domains) {
      if (domain.sub_domains.length) {
        for (let subDomain of domain.sub_domains) {
          // @ts-ignore
          domains = filterDomains(domains, subDomain);
        }
      }
    }

    function buildDataNode(domain: Specialization) {
      const dataNode: any = {
        key: domain.id,
        title: domain.name,
        value: domain.id,
      };

      if (domain.sub_domains && domain.sub_domains.length > 0) {
        dataNode.children = domain.sub_domains
          .filter((subDomain: any) => initialDomainIds.includes(subDomain.id))
          .map((subDomain: any) => buildDataNode(subDomain));
      }

      return dataNode;
    }

    domains.forEach((domain, idx) => {
      let structuredDomains = {
        title: domain.name,
        value: domain.id,
        children: [],
      };
      // @ts-ignore
      if (domain.sub_domains.length) {
        // @ts-ignore
        domain.sub_domains
          .filter((subDomain: any) => initialDomainIds.includes(subDomain.id))
          .forEach((sub_domain: any, index) => {
            let dataNode = buildDataNode(sub_domain);
            if (dataNode) {
              // @ts-ignore
              structuredDomains.children.push(dataNode);
            }
          });
      }
      structured.push(structuredDomains);
    });
    return structured;
  };

  const [value, setValue] = useState(['']);

  const onChange = (newValue: string[]) => {
    setValue(newValue);
  };

  return (
    <>
      {/* Job Title/Code search - available for all user types */}
      <Col
        xs={{ span: 24 }}
        md={{ span: 12 }}
        xl={{ span: xlColSpan4 }}
      >
        <FormItem
          label={t('JOB_TITLE_OR_CODE_LABEL')}
          name="search"
          tooltip={{ title: t('JOB_TITLE_OR_CODE_INFO_TEXT'), icon: <InfoIcon /> }}
        >
          <Input
            name="search"
            size="large"
            type="text"
            placeholder={t('JOB_TITLE_OR_CODE_PLACEHOLDER')}
            className="custom-select"
          />
        </FormItem>
      </Col>

      {isClient && (
        <Col xs={{ span: 12 }} md={{ span: 8 }} xl={{ span: xlColSpan4 }}>
          <FormItem label={t('JOB_STATUS_TITLE')} name="job_status">
            <SelectInput
              size="large"
              showSearch
              placeholder={t('JOB_STATUS_PLACEHOLDER')}
              optionFilterProp="children"
              filterOption={true}
              dropdownMatchSelectWidth={false}
              className="custom-select"
              suffixIcon={<ArrowDownIcon />}
            >
              <Option value="">{t('NONE')}</Option>
              <Option value="online">{t('JOB_STATUS_ONLINE')}</Option>
              <Option value="offline">{t('JOB_STATUS_OFFLINE')}</Option>
            </SelectInput>
          </FormItem>
        </Col>
      )}
      {!isClient && (
        <>
          {/* Domain/specialization filter */}
          <Col xs={{ span: 12 }} md={{ span: 8 }} xl={{ span: xlColSpan1 }}>
            <FormItem label={t('DOMAIN_TITLE')} name="domain">
              <TreeSelectInput
                size="large"
                showSearch
                placeholder={t('DOMAIN_PLACEHOLDER')}
                className="custom-select"
                value={value}
                suffixIcon={<ArrowDownIcon />}
                onChange={onChange}
                treeData={structuredDomains()}
                treeNodeFilterProp="title"
                allowClear
                multiple
              ></TreeSelectInput>
            </FormItem>
          </Col>

          {/* Seniority filter */}
          <Col xs={{ span: 12 }} md={{ span: 8 }} xl={{ span: xlColSpan1 }}>
            <FormItem label={t('SENIORITY_LABEL')} name="seniority">
              <SelectInput
                size="large"
                showSearch
                placeholder={t('SENIORITY_PLACEHOLDER')}
                optionFilterProp="children"
                filterOption={true}
                className="custom-select"
                suffixIcon={<ArrowDownIcon />}
              >
                <Option value="">{t('NONE')}</Option>
                {data.seniority.map((sen) => (
                  <Option value={sen.id} key={sen.id}>
                    {sen.name}
                  </Option>
                ))}
              </SelectInput>
            </FormItem>
          </Col>

          {/* Location filter */}
          <Col xs={{ span: 12 }} md={{ span: 8 }} xl={{ span: xlColSpan3 }}>
            <FormItem label={t('LOCATION_LABEL')} name="location">
              <SelectInput
                size="large"
                showSearch
                placeholder={t('LOCATION_PLACEHOLDER')}
                optionFilterProp="children"
                filterOption={true}
                dropdownMatchSelectWidth={false}
                className="custom-select"
                suffixIcon={<ArrowDownIcon />}
              >
                <Option value="">{t('NONE')}</Option>
                {data.cities.map((city) => (
                  <Option value={city.id} key={city.id}>
                    {city.name}
                  </Option>
                ))}
              </SelectInput>
            </FormItem>
          </Col>

          {/* Salary range filter */}
          <Col xs={{ span: 24 }} md={{ span: 12 }} xl={{ span: xlColSpan5 }}>
            <FormItem label={t('SALARY_RANGE_LABEL')}>
              <Row gutter={8}>
                <Col xs={{ span: 12 }} md={{ span: 12 }} xl={{ span: 12 }}>
                  <FormItem
                    label=""
                    name="salary_from"
                    className="no-margin"
                    rules={[
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          const to = getFieldValue('salary_to');
                          if (to && value > to) {
                            return Promise.reject(t('SALARY_RANGE_ERROR'));
                          }
                          return Promise.resolve();
                        },
                      }),
                    ]}
                  >
                    <NumberInput
                      size="large"
                      type={'number'}
                      placeholder={t('SALARY_FROM_PLACEHOLDER')}
                    />
                  </FormItem>
                </Col>
                <Col xs={{ span: 12 }} md={{ span: 12 }} xl={{ span: 12 }}>
                  <FormItem label="" name="salary_to" className="no-margin">
                    <NumberInput
                      size="large"
                      type={'number'}
                      placeholder={t('SALARY_TO_PLACEHOLDER')}
                    />
                  </FormItem>
                </Col>
              </Row>
            </FormItem>
          </Col>

          {isRecruiter && (
            <>
              <Col xs={{ span: 12 }} md={{ span: 8 }} xl={{ span: xlColSpan2 }}>
                <FormItem label={t('COMPANY_NAME_TITLE')} name="company_name">
                  <Input
                    name="company_name"
                    size="large"
                    type="text"
                    placeholder={t('COMPANY_NAME_TITLE')}
                    className="custom-select"
                  />
                </FormItem>
              </Col>
              <Col xs={{ span: 12 }} md={{ span: 8 }} xl={{ span: xlColSpan2 }}>
                <FormItem label={t('VISA')} name="visa">
                  <SelectInput
                    size="large"
                    showSearch
                    placeholder={t('VISA_SELECT')}
                    optionFilterProp="children"
                    filterOption={true}
                    className="custom-select"
                    suffixIcon={<ArrowDownIcon />}
                  >
                    <Option value="">{t('NONE')}</Option>
                    <Option value="true">Visa sponsorship possible</Option>
                    <Option value="false">No visa sponsorship possible</Option>
                  </SelectInput>
                </FormItem>
              </Col>

              <Col xs={{ span: 12 }} md={{ span: 8 }} xl={{ span: xlColSpan1 }}>
                <FormItem label={t('WORKING_TYPE')} name="working_type">
                  <SelectInput
                    size="large"
                    showSearch
                    placeholder={t('WORKING_TYPE_SELECT')}
                    optionFilterProp="children"
                    filterOption={true}
                    dropdownMatchSelectWidth={false}
                    className="custom-select"
                    suffixIcon={<ArrowDownIcon />}
                  >
                    <Option value="">{t('NONE')}</Option>
                    <Option value="full_office">
                      {t('WORKING_TYPE_FULL_OFFICE')}
                    </Option>
                    <Option value="hybrid">Hybrid</Option>
                    <Option value="fully_remote_home_country">
                      Fully remote in home country
                    </Option>
                    <Option value="fully_remote_same_timezone">
                      Fully remote within same timezone
                    </Option>
                    <Option value="fully_remote_worldwide">
                      Fully remote worldwide
                    </Option>
                  </SelectInput>
                </FormItem>
              </Col>

              <Col xs={{ span: 12 }} md={{ span: 8 }} xl={{ span: xlColSpan2 }}>
                <FormItem label={t('LANGUAGE')} name="language">
                  <SelectInput
                    size="large"
                    showSearch
                    placeholder={t('REQUIRED_LANGUAGE')}
                    optionFilterProp="children"
                    filterOption={true}
                    className="custom-select"
                    suffixIcon={<ArrowDownIcon />}
                  >
                    <Option value="">{t('NONE')}</Option>
                    {Object.keys(dictionary).map(function (key) {
                      // @ts-ignore
                      return <option value={key}>{dictionary[key]}</option>;
                    })}
                  </SelectInput>
                </FormItem>
              </Col>
              <Col xs={{ span: 12 }} md={{ span: 8 }} xl={{ span: xlColSpan2 }}>
                <FormItem label={t('RELOCATION')} name="relocation">
                  <SelectInput
                    size="large"
                    showSearch
                    placeholder={t('RELOCATION_OPTION')}
                    optionFilterProp="children"
                    filterOption={true}
                    className="custom-select"
                    suffixIcon={<ArrowDownIcon />}
                  >
                    {relocationOptionsFilters.map(({ id, label }) => (
                      <Option key={id} value={`${id}`}>
                        {t(label)}
                      </Option>
                    ))}
                  </SelectInput>
                </FormItem>
              </Col>
            </>
          )}
        </>
      )}
    </>
  );
};

export default JobFilter;
