import React from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { CANDIDATES } from '../../constants/routes';
import { useTranslation } from 'react-i18next';
import { ColumnsType } from 'antd/es/table';
import { Table } from 'antd';

import { CandidateInfo, InterviewRoom } from '../../types';
import CandidateNameAvatar from '../candidates/CandidateNameAvatar';
import JobListInfoItem from '../jobs/jobList/JobListInfoItem';
import { ReactComponent as StarRoundedIcon } from '../../icons/star-rounded.svg';
import { ReactComponent as LocationIcon } from '../../icons/location.svg';
import SalaryText from '../candidates/SalaryText';
import CandidateInterviewColumn from '../candidates/CandidateInterviewColumn';
import CandidateActionButton from '../candidates/CandidateActionButton';
import CandidateRecruiterInfo from '../candidates/CandidateRecruiterInfo';
import { addDecimalPoints, generateKey } from '../../utils/candidate';
import useCurrency from '../../hooks/useCurrency';

interface CandidateList {
  candidates: CandidateInfo[];
  setCandidates: React.Dispatch<React.SetStateAction<CandidateInfo[]>>;
}

const StyledTable = styled(Table)`
  & .ant-table-tbody > tr > td {
    vertical-align: top;

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;
      & button {
        margin-left: auto;
      }
    }
  }
`;

const AdminCandidates = ({ candidates, setCandidates }: CandidateList) => {
  const [t] = useTranslation();
  const defaultCurrency = useCurrency();
  const history = useHistory();
  const openModal = (candidate: CandidateInfo) => {
    const search = new URLSearchParams(history.location.search);
    search.set('history', candidate.id);
    if (search.get('filter') && candidate.interviews && candidate.interviews.length > 0) {
      search.set('interview_id', candidate.interviews[0]?.id);
    }
    const query = search.toString();
    history.push(CANDIDATES + '?' + query);
  };

  const columns: ColumnsType<any> = [
    {
      title: '',
      width: 180,
      key: generateKey(5),
      responsive: ['sm', 'xxl', 'xl', 'lg', 'md'],
      dataIndex: 'name',
      render: (name, record) => (
        <CandidateNameAvatar
          name={name}
          color={record.color}
          company={record.current_company}
          role={record.current_role}
          onClick={() => openModal(record)}
        />
      ),

      fixed: 'left',
    },
    {
      title: '',
      width: 180,
      dataIndex: 'name',
      key: generateKey(5),
      responsive: ['xs'],
      render: (name, record) => (
        <CandidateNameAvatar
          name={name}
          color={record.color}
          company={record.current_company}
          role={record.current_role}
          onClick={() => openModal(record)}
        />
      ),
    },
    {
      title: '',
      width: 130,
      key: generateKey(5),
      dataIndex: 'travel_max',
      render: (_, record) => {
        return (
          <div>
            <JobListInfoItem
              icon={<StarRoundedIcon />}
              title={
                record.specializations?.length
                  ? record.specializations.join(', ')
                  : '-'
              }
            />
            <JobListInfoItem
              icon={<LocationIcon />}
              title={record.city_of_residence ? record.city_of_residence : '-'}
            />
          </div>
        );
      },
    },
    {
      title: '',
      width: 160,
      key: generateKey(5),
      dataIndex: 'interviews',
      render: (interviews) => (
        <>
          {interviews.map((interview: InterviewRoom) => (
            <CandidateInterviewColumn
              key={interview.id}
              interview={interview}
            />
          ))}
        </>
      ),
    },
    {
      title: '',
      width: 172,
      key: generateKey(5),
      dataIndex: 'salary',
      render: (salary, record) => {
        const { curr } = defaultCurrency.changeCurrency(record.compensation_currency);
        return (
          <div>
            <SalaryText>{`${t('MONTHLY_SALARY')}: ${curr(addDecimalPoints(
              salary.toString()),
            )}`}</SalaryText>
            <SalaryText>{`${t('TOTAL_SALARY')}: ${curr(salary &&
              addDecimalPoints(
                Math.round(
                  parseInt(salary) * 12.96 +
                  (record.bonus ? 12 * record.bonus : 0),
                ).toString(),
              )
            )}`}</SalaryText>
          </div>
        )
      },
    },
    {
      title: '',
      width: 170,
      key: generateKey(5),
      dataIndex: 'created_by',
      render: (created_by, record) => {
        return created_by && created_by?.role !== 'admin' ? (
          <CandidateRecruiterInfo
            recruiter={{ ...created_by, company: record.company, id: record.created_by.id }}
            color={record.color}
          />
        ) : (
          <CandidateNameAvatar
            name={record?.company?.name}
            color={record?.color}
          />
        );
      },
    },
    {
      title: '',
      width: 50,
      key: generateKey(5),
      responsive: ['sm', 'xxl', 'xl', 'lg', 'md'],
      dataIndex: 'id',
      fixed: 'right',
      render: (id, record) => (
        <CandidateActionButton candidate={record} onAction={setCandidates} />
      ),
    },
    {
      title: '',
      width: 50,
      dataIndex: 'id',
      key: generateKey(5),
      responsive: ['xs'],
      render: (id, record) => (
        <CandidateActionButton candidate={record} onAction={setCandidates} />
      ),
    },
  ];

  return (
    <StyledTable
      locale={{ emptyText: ' ' }}
      columns={columns}
      dataSource={candidates}
      showHeader={false}
      scroll={{ x: 950 }}
      pagination={false}
      rowKey={() => generateKey(5)}
    />
  );
};

export default AdminCandidates;
