import axios from 'axios';
import type { History } from 'history';

const url = process.env.REACT_APP_API_URL;

export const getCandidateList = (
  query?: URLSearchParams | string,
  filter?: string | null,
) => {
  return axios.get(`${url}candidates/${filter ? filter + '/' : query}`);
};

export const getCandidateListByMatch = (
  jobId:string,
  query?: URLSearchParams | string,
  filter?: string | null,
) => {
  return axios.get(`${url}jobs/${jobId}/candidates/${filter ? filter + '/' : query}`);
};

export const createCandidate = (data: any) => {
  return axios.post(`${url}candidates/`, data);
};

export const updateCandidate = (data: any, id: string) => {
  return axios.patch(`${url}candidates/${id}/`, data);
};

export const deleteCandidate = (id: string) => {
  return axios.delete(`${url}candidates/${id}/`);
};

export const inviteCandidate = (id: string,data:any) => {
  return axios.post(`${url}interviews/${id}/invite/`,data);
};

export const introduceCandidate = (id: string,data:any) => {
  return axios.post(`${url}candidates/${id}/introduce/`,data);
};

export const rejectCandidateInterview = (id: string,data:any) => {
  return axios.post(`${url}interviews/${id}/reject/`,data);
};

export const getCandidate = (id:string) => {
  return axios.get(`${url}candidates/${id}/`)
  .then((res) => res)
  .catch((e) => {
    return e.response;
  });
};

export const getCandidateByInterview = (id:string) => {
  return axios.get(`${url}interviews/${id}/candidate-profile/`)
  .then((res) => res)
  .catch((e) => {
    return e.response;
  });
};

export const getCandidateHistory = (id:string, history?: History) => {
  return axios.get(`${url}interviews/candidate-history/${id}/`)
  .then((res) => res)
  .catch((e) => {
    if (e?.response?.data?.error_code === 'resource_not_found' && history) history.push('/not-found');
    return e.response;
  });
};

export const getCandidatesStagesStatuses = () => {
  return axios.get(`${url}candidates-stage-statuses/`)
      .then((res) => res)
      .catch((e) => {
        return e.response;
      });
};

export const setCandidatesStagesStatuses = (interview_id: string, status: string) => {
  return axios.patch(`${url}interviews/${interview_id}/candidate-stage-status/`, {
    'candidate_stage_status': status
  })
      .then((res) => res)
      .catch((e) => {
        return e.response;
      });
};

export const uploadCandidateCv = (formData: FormData) => {
  return axios.post(`${url}candidates/upload/`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data', // Important: Set content type
    },
  })
  .then((res) => res)
  .catch((e) => {
    return e.response;
  });
}
