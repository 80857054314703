import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import useHirePortContext from './useHirePortContext';

const LOCALE_STORAGE_KEY = 'app_locale';
const ORG_LOCAL_STORAGE_KEY = 'org_locale';

const FALLBACK_LOCALE = 'en';

const getDefaultLocale = () => {
  return localStorage.getItem(ORG_LOCAL_STORAGE_KEY) || FALLBACK_LOCALE;
}

const setDefaultLocale = (locale?: string) => {
  if (!locale) {
    localStorage.removeItem(ORG_LOCAL_STORAGE_KEY);
    return;
  }

  localStorage.setItem(ORG_LOCAL_STORAGE_KEY, locale);
}

const getRawBrowserLocale = () => {
  return localStorage.getItem(LOCALE_STORAGE_KEY);
}

export const getBrowserLocale = () => {
  return getRawBrowserLocale() || getDefaultLocale();
}

const setBrowserLocale = (locale?: string) => {
  if (!locale) {
    localStorage.removeItem(LOCALE_STORAGE_KEY);
    return;
  }
  localStorage.setItem(LOCALE_STORAGE_KEY, locale);
}

type UseLocale = {
  /** the default locale used by the organization relating to the current user */
  defaultLocale: string;
  /** the ephemeral locale stored in React state, which defaults to browserLocale unless updated */
  sessionLocale: string;
  /** the persistent locale stored in local storage */
  browserLocale: string;
  /** sets the default locale used by the organization relating to the current user and, 
   * if the session locale is not set, the session and browser locale to match */
  setDefaultLocale: typeof setDefaultLocale;
  /** sets the ephemeral locale stored in React state */
  setSessionLocale: typeof setDefaultLocale;
  /** sets both the persistent local storage locale, and the React state locale */
  setBrowserLocale: typeof setDefaultLocale;
};

/** Utility hook to allow manipulation of two levels of locale preference:
 * 1. "browserLocale" which is a persistent locale string stored in local storage
 * 2. "sessionLocale" which can be overridden on an ephemeral basis, but which defaults to browserLocale
 * 
 * Use this hook in preference to manipulating localStorage directly
 */
const useLocale = () => {
  const [, i18n] = useTranslation();
  const browserLocale = getBrowserLocale();
  const defaultLocale = getDefaultLocale();
  const context = useHirePortContext();

  const setSessionLocale = useCallback((locale?: string) => {
    context.sessionLocale = locale;
    i18n.changeLanguage(locale);
  }, [context, i18n]);

  const setBrowserAndSessionLocale = useCallback((locale?: string) => {
    setBrowserLocale(locale);
    setSessionLocale(locale);
  }, [setSessionLocale]);

  const setDefaultAndMaybeBrowserLocale = useCallback((locale?: string) => {
    setDefaultLocale(locale);
    // only set the browser/session locale if the browser URL is already unset
    if (!getRawBrowserLocale()) {
      setBrowserAndSessionLocale(locale);
    }
  }, [setBrowserAndSessionLocale]);

  const fn: UseLocale = useMemo(() => ({
    defaultLocale,
    setDefaultLocale: setDefaultAndMaybeBrowserLocale,
    sessionLocale: context.sessionLocale || browserLocale,
    setSessionLocale,
    browserLocale,
    setBrowserLocale: setBrowserAndSessionLocale,
  }), [context, setBrowserAndSessionLocale, setSessionLocale, browserLocale, defaultLocale, setDefaultAndMaybeBrowserLocale]);

  return fn;
};

export default useLocale;
