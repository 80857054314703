export const NOTIFICATION_TYPES: { [key: number]: string } = {
  1: 'SYSTEM_TYPE',
  2: 'DATES_PROPOSED',
  3: 'DATE_CONFIRMED',
  4: 'FEEDBACK_REQUIRED',
  5: 'FEEDBACK_RECEIVED',
  6: 'NEW_CANDIDATE',
  7: 'NEW_MESSAGE',
  8: 'JOB_PUBLISHED',
  9: 'PREFERENCE_RELEVANCE_INTERVAL',
  10: 'OFFER_MAKE',
  11: 'OFFER_CONFIRM',
  12: 'OFFER_REJECT',
  13: 'OFFER_DISAGREE',
  14: 'CANDIDATE_INVITE',
  15: 'CANDIDATE_REJECT',
  16: 'RATE',
  30: 'JOB_UNPUBLISHED',
  31: 'JOB_UPDATED',
  35: 'JOB_ON_HOLD',
  38: 'JOB_ACTIVITY_FEED',
};
