import { message } from 'antd';
import type { RcFile } from 'antd/lib/upload';
import type React from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Loader from '../../UI/Loader';
import { ReactComponent as InfoIcon } from '../../icons/info-icon-rounded.svg';
import type { BulkRecapItem } from './BulkUploadRecap'

export interface BulkUploadCvsProps {
  onFileUpload: (file: RcFile) => void;
  onFilesDrop: (files: BulkRecapItem[]) => void;
}

const Overlay = styled.div`
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const DropZone = styled.div`
  background-color: #fff;
  border: 2px dashed ${({ theme }) => theme.primaryColor};
  border-radius: ${({ theme }) => theme.radius};;
  padding: 6rem 0;
  text-align: center;
  transition: all 0.3s;
  width: 75%;
  max-width: 38rem;
  cursor: pointer;

  & h2 {
    font-size: 1rem;
    font-weight: 200;
    color: #627D95;
  }

  & p {
    font-size: 0.875rem;
    font-weight: 400;
    margin: 0;
    color: #627D95;
    margin: 0 0.5rem;
  }

  & div {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &.dragging-over {
    background-color: ${({ theme }) => theme.lozengeColor};
    transform: scale(1.02);
  }
`;

// Helper function to check if file type is supported
const isSupportedFileType = (type: string): boolean => {
  return [
    'application/pdf',                     // PDF
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // DOCX
    'application/msword',                  // DOC
    'text/plain'                           // TXT
  ].includes(type);
};

const BulkUploadCvs: React.FC<BulkUploadCvsProps> = ({ onFileUpload, onFilesDrop }) => {
  const [t] = useTranslation();
  const [isVisible, setIsVisible] = useState(false);
  const [isDraggingOver, setIsDraggingOver] = useState(false);
  const [isUploading, setIsUploading] = useState(false);

  useEffect(() => {
    const handleWindowDragEnter = (e: DragEvent) => {
      e.preventDefault();
      e.stopPropagation();

      if (e.dataTransfer?.items) {
        const items = Array.from(e.dataTransfer.items);
        const hasValidFile = items.some(item => isSupportedFileType(item.type));
        if (hasValidFile) {
          setIsVisible(true);
        }
      }
    };

    const handleWindowDragOver = (e: DragEvent) => {
      e.preventDefault();
      e.stopPropagation();
    };

    const handleWindowDragLeave = (e: DragEvent) => {
      e.preventDefault();
      e.stopPropagation();

      // Only hide if leaving the window
      if (
        e.clientX <= 0 ||
        e.clientY <= 0 ||
        e.clientX >= window.innerWidth ||
        e.clientY >= window.innerHeight
      ) {
        setIsVisible(false);
        setIsDraggingOver(false);
      }
    };

    const handleWindowDrop = (e: DragEvent) => {
      e.preventDefault();
      e.stopPropagation();
      setIsVisible(false);
      setIsDraggingOver(false);
    };

    window.addEventListener('dragenter', handleWindowDragEnter);
    window.addEventListener('dragover', handleWindowDragOver);
    window.addEventListener('dragleave', handleWindowDragLeave);
    window.addEventListener('drop', handleWindowDrop);

    return () => {
      window.removeEventListener('dragenter', handleWindowDragEnter);
      window.removeEventListener('dragover', handleWindowDragOver);
      window.removeEventListener('dragleave', handleWindowDragLeave);
      window.removeEventListener('drop', handleWindowDrop);
    };
  }, []);

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();

    const items = Array.from(e.dataTransfer.items);
    const hasValidFile = items.some(item => isSupportedFileType(item.type));

    if (hasValidFile) {
      setIsDraggingOver(true);
      e.dataTransfer.dropEffect = 'copy';
    } else {
      e.dataTransfer.dropEffect = 'none';
    }
  };

  const handleDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDraggingOver(false);
  };

  const handleDrop = async (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDraggingOver(false);
    setIsUploading(true);

    const files = Array.from(e.dataTransfer.files);
    const validFiles = files.filter(file => isSupportedFileType(file.type));

    if (validFiles.length === 0) return;

    try {
      // Notify the upload recap component
      const queue = validFiles.map(file => ({ cv: file.name }))
      onFilesDrop(queue);

      // Hide dropzone
      setIsVisible(false);
      setIsUploading(false);

      // Parallel upload of files
      await Promise.all(validFiles.map(file => onFileUpload(file as RcFile)));

      message.success(t('RECRUITER_BULK_UPLOAD_SUCCESS'));
    } catch (error) {
      message.error(t('RECRUITER_BULK_UPLOAD_ERROR'));
    }
  };

  if (!isVisible) {
    return null;
  }

  return (
    <Overlay>
      <DropZone
        className={isDraggingOver ? 'dragging-over' : ''}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
      >
        {isUploading
          ? <Loader />
          : <>
            <h2>{t('RECRUITER_BULK_UPLOAD_TITLE')}</h2>
            <div>
              <InfoIcon />
              <p>{t('RECRUITER_BULK_UPLOAD_DESCRIPTION')}</p>
            </div>
          </>
        }
      </DropZone>
    </Overlay>
  );
};

export default BulkUploadCvs;
