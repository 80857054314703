import React from 'react';
import styled from 'styled-components';
import { ReactComponent as PhoneIcon } from '../../../icons/phone.svg';
import { ReactComponent as MessageIcon } from '../../../icons/messages.svg';
import { ReactComponent as LinkedinIcon } from '../../../icons/linkedin-outlined.svg';
import { ReactComponent as DownloadIcon } from '../../../icons/download.svg';
import { ReactComponent as EyeIcon } from '../../../icons/eye.svg';
import { useHistory } from 'react-router-dom';
import { Candidate } from '../../../types';
import { getNameFirstLetters } from '../../../utils/candidate';
import { useTranslation } from 'react-i18next';
import { navigate } from '../../../utils/routes';
import { OFFERED } from '../../../constants/statuses';

interface CandidateModalProps {
  user: Candidate & { stage_name: string };
  stage: string;
}

const CandidateModalWrapper = styled.div`
  padding: 2rem 2rem 1.5rem;
  border-radius: ${({ theme }) => theme.radius};
  box-shadow: 0 2rem 5rem 0 rgba(6, 28, 46, 0.2),
    0 1rem 1.25rem 0 rgba(6, 28, 46, 0.16);
  background-color: #ffffff;
  position: absolute;
  top: calc(100% + 0.5rem);
  left: 0;
  z-index: 999;
  width: auto;
  min-width: 17rem;
  @media (max-width: 576px) {
    padding: 1.5rem;
    transform: translateX(-50%);
  }
`;
const ModalHeader = styled.div`
  display: flex;
  margin-bottom: 1.5rem;
  align-items: center;
`;
const ModalHeaderTitle = styled.h4`
  font-size: 0.875rem;
  font-weight: 700;
  line-height: 1.43;
  color: #061c2e;
  margin-bottom: 0.25rem;
`;
const ModalHeaderDescription = styled.p`
  font-size: 0.75rem;
  line-height: 1.33;
  color: #627d95;
  margin: 0;
`;
const CandidateAvatar = styled.div`
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  background: rgba(78, 67, 206, 0.1);
  font-size: 0.875rem;
  line-height: 1.71;
  color: #4e43ce;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 0.5rem;
`;
const ModalBody = styled.div`
  border-bottom: 1px solid #dae5ec;
  padding-bottom: 0.5rem;
  margin-bottom: 1rem;
`;
const ModalBodyItem = styled.p`
  font-size: 0.875rem;
  line-height: 1.71;
  color: #061c2e;
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
  & svg {
    color: #627d95;
    margin-right: 1rem;
    font-size: 1.5rem;
  }
`;
const CandidateModal = ({ user, stage }: CandidateModalProps) => {
  const fullName = getNameFirstLetters(user.name);
  const history = useHistory();
  const [t] = useTranslation();
  return (
    <CandidateModalWrapper>
      <ModalHeader>
        <CandidateAvatar>{fullName}</CandidateAvatar>
        <div>
          <ModalHeaderTitle>{user.name}</ModalHeaderTitle>
          <ModalHeaderDescription>{user?.stage_name === OFFERED ? t('OFFER_STAGE') : user?.stage_name}</ModalHeaderDescription>
        </div>
      </ModalHeader>
      <ModalBody>
        <ModalBodyItem>
          <PhoneIcon />
          {user.phone}
        </ModalBodyItem>
        <ModalBodyItem>
          <MessageIcon />
          {user.email}
        </ModalBodyItem>
        <a href={user.linkedin_link} rel="noreferrer" target="_blank">
          <ModalBodyItem>
            <LinkedinIcon />
            {t('LINKEDIN_PROFILE_TITLE')}
          </ModalBodyItem>
        </a>
        <a href={user.cv} rel="noreferrer" target="_blank">
          <ModalBodyItem>
            <DownloadIcon />
            {t('DOWNLOAD_CV_TITLE')}
          </ModalBodyItem>
        </a>
      </ModalBody>
      <a
        href={'/'}
        onClick={(e) => {
          e.preventDefault();
          navigate(history, {
            history: user.id,
            interview_id: user.interview_id,
          });
        }}
      >
        <ModalBodyItem>
          <EyeIcon />
          {t('VIEW_PROFILE_TITLE')}
        </ModalBodyItem>
      </a>
    </CandidateModalWrapper>
  );
};

export default CandidateModal;
