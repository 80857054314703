import { HIRED, INTRODUCED, ON_HOLD, SOURCED } from '../../../constants/statuses';
import { InterviewStage } from '../../../types';

// !WB: I've extracted this complex function so that it's more amenable to unit testing and it's easier to reason about without other noise
export const getDisallowedColumns = (
  allKanbanColumns: string[],
  allKnownInterviewStages: InterviewStage[] | null,
  jobHiringProcessInterviewStageNames: string[],
  currentStageName: string,
  userIsSourcer: boolean | null,
  userIsRecruiter: boolean,
  recruiterCanMoveCandidates: boolean,
) => {
  // !WB: this was totally bonkers before and is still a little complicated
  // it's now a single reducer which does a series of checks in order, I've commented them all...
  // note, there was an accumulated field called `isPassed` which was used to prevent backwards transitions,
  // but that's now been removed. I kept the nested reducer subject though, with a single field `disabledFields`
  // as it would allow other accumulated fields to be added in future with minimal effort.
  const { disabledFields } = allKanbanColumns.reduce(({ disabledFields }, targetStageName) => {
    const disallowedField = () => ({ disabledFields: { ...disabledFields, [targetStageName]: true } });
    const allowedField = () => ({ disabledFields: { ...disabledFields, [targetStageName]: false } });

    // check to see if it's a known interview stage...
    const matchingInterviewStage = allKnownInterviewStages?.find((interviewStage) => interviewStage.name.toLowerCase() === targetStageName.toLowerCase());
    // ...if it's a known interview stage, but it isn't represented in this job, disable it always
    if (matchingInterviewStage && !jobHiringProcessInterviewStageNames.includes(matchingInterviewStage.name))
      return disallowedField();

    // always enable the current state, this is just polite :)
    if (targetStageName === currentStageName)
      return allowedField();

    // always allow movement to on hold, except by sourcers
    if (targetStageName === ON_HOLD && !userIsSourcer)
      return allowedField();

    // if recruiter moving candidates is disabled, block all movement (except ON_HOLD as per above)
    if (userIsRecruiter && !recruiterCanMoveCandidates)
      return disallowedField();

    // always disable hired column for recruiter
    if (targetStageName === HIRED && userIsRecruiter)
      return disallowedField();

    // never allow movement back to sourced from elsewhere
    if (targetStageName === SOURCED)
      return disallowedField();

    // sourcers cannot move candidates anywhere else
    if (userIsSourcer)
      return disallowedField();

    // candidates in sourced can ONLY move to introduced, and candidates can ONLY move to introduced from sourced
    if ((currentStageName === SOURCED && targetStageName !== INTRODUCED) || (targetStageName === INTRODUCED && currentStageName !== SOURCED))
      return disallowedField();

    // allow movement to any stage from on hold
    if (currentStageName === ON_HOLD)
      return allowedField();

    // otherwise allow the movement
    return allowedField();
  }, { disabledFields: {} } as { disabledFields: Record<string, boolean> });

  return disabledFields;
};
