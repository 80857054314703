import { Col, Form, Row, Tabs, message } from 'antd';
import type React from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import Tab from '../../UI/Tab';
import Button from '../../UI/buttons/Button';
import { getCandidateHistory } from '../../api/candidates';
import { CANDIDATES } from '../../constants/routes';
import useCurrency from '../../hooks/useCurrency';
import { ReactComponent as EditIcon } from '../../icons/edit.svg';
import { useAppSelector } from '../../store/hooks';
import type { CandidateHistory, CandidateInfo } from '../../types';
import getRecruiterFeeSummary from '../../utils/getRecruiterFeeSummary';
import { navigate } from '../../utils/routes';
import CandidateRejectForm from '../form/CandidateRejectForm';
import FormItem from '../form/FormItem';
import FormPopUp from '../form/FormPopUp';
import CandidatePersonalInfo from './CandidatePersonalInfo';
import GeneralInfo from './GeneralInfo';
import HistoryList from './HistoryList';
import InfoItem from './InfoItem';
import SalaryPackage from './SalaryPackage';

import {
  rejectCandidateInterview,
} from '../../api/candidates';

interface CandidateInfoProps {
  current: CandidateInfo | null;
  setCurrent: React.Dispatch<React.SetStateAction<CandidateInfo | null>>;
  setCandidates?: React.Dispatch<React.SetStateAction<CandidateInfo[]>>;
}

const StyledButton = styled(Button)`
  text-transform: capitalize;
  margin-top: 1rem;
`;

const StyledForm = styled(Form)`
  margin-top: 4rem;
`;

const Info = styled.p`
  font-size: 0.875rem;
  line-height: 1.71;
  color: #061c2e;
  background-color: #ffffff;
  margin: 0;
  white-space: pre-line;
`;

const { TabPane } = Tabs;
const CandidateHistoryModal = ({ current, setCurrent, setCandidates }: CandidateInfoProps) => {
  const [loadingReject, setLoadingReject] = useState(false);
  const [reject, setReject] = useState(false);
  const [visible, setVisible] = useState(!!current);
  const [history, setHistory] = useState<CandidateHistory[]>([]);
  const routerHistory = useHistory();
  const params = new URLSearchParams(routerHistory.location.search);
  const filter = params.get('filter');
  const candidate = params.get('history');
  const interview_id = params.get('interview_id');
  const [t] = useTranslation();
  const currency = useCurrency(current?.fee_currency);
  const { user } = useAppSelector((state) => state.user);
  const recruiterFeeSummary = current ? getRecruiterFeeSummary(
    t,
    currency,
    current,
    false,
  ) : null;

  // Find the specific interview that matches the interview_id from the URL
  const selectedInterview = current?.interviews?.find(interview => interview.id === interview_id);

  useEffect(() => {
    setVisible(!!current);
  }, [current]);

  useEffect(() => {
    if (!visible) {
      navigate(routerHistory, undefined, [
        'history',
        'interview_id',
        'stage_name',
      ]);
    } else {
      candidate &&
        getCandidateHistory(candidate, routerHistory).then((res) =>
          setHistory(res.data),
        );
    }
  }, [visible, candidate, routerHistory]);

  const onEdit = () => {
    params.delete('history');
    params.delete('interview_id');
    current && params.set('edit', current.id);
    setCurrent(null);
    routerHistory.push(`${CANDIDATES}/?${params.toString()}`);
  };

  const onReject = (rejection_reason: string) => {
    setLoadingReject(true);
    if (selectedInterview?.id) {
      setCandidates &&
        rejectCandidateInterview(selectedInterview.id, {
          rejection_reason,
        })
        .then(() => {
          setCandidates((prevState) => {
            setLoadingReject(false);
            return prevState.map((prev) => {
              if (prev.id === candidate) {
                return {
                  ...prev,
                  interviews: prev.interviews.map((int) => {
                    if (int.id === selectedInterview.id) {
                      return { ...int, status: 'rejected' };
                    }
                    return int;
                  }),
                };
              }
              return prev;
            });
          });
          setVisible(false);
          message.success(t('CANDIDATE_REJECT_MESSAGE'), 5);
        });
    }
    return true;
  };

  return (
    <FormPopUp
      visible={visible}
      setVisible={setVisible}
      title={t('CANDIDATE_INFO')}
      large={true}
    >
      <CandidatePersonalInfo
        name={current?.name || ''}
        color={current?.color}
        role={current?.current_role}
        company={`${t('AT')} ${current?.current_company && current?.current_company
          }`}
        cv={current?.cv as string}
        linkedin={current?.linkedin_link as string}
      />
      <Tab defaultActiveKey="1" className="mt-5">
        <TabPane tab={t('CANDIDATE_INFO')} key="1">
          <Row justify="space-between">
            <Col xs={{ span: 24 }} lg={{ span: 11 }} xl={{ span: 10 }}>
              <GeneralInfo candidate={current as CandidateInfo} />
            </Col>
            {user?.hide_salary_info ? (
              <></>
            ) : (
              <Col xs={{ span: 24 }} lg={{ span: 11 }} xl={{ span: 10 }}>
                <SalaryPackage candidate={current as CandidateInfo} />
              </Col>
            )}
          </Row>
          <StyledForm
            onFinish={onEdit}
            layout="vertical"
            scrollToFirstError={true}
          >
            {current?.additional_info && (
              <FormItem
                label={`${t('ADDITIONAL_INFO')}`}
                className={'text-grey'}
              >
                <Info>{current?.additional_info}</Info>
              </FormItem>
            )}
            {!filter && (
              <FormItem>
                <StyledButton
                  size="large"
                  type="primary"
                  htmlType="submit"
                  icon={<EditIcon />}
                >
                  {t('EDIT_TITLE')}
                </StyledButton>
              </FormItem>
            )}
          </StyledForm>
          {interview_id && selectedInterview && selectedInterview.status === 'introduced' && <StyledButton
            size="large"
            type="default"
            danger
            onClick={() => setReject(true)}
            disabled={selectedInterview.status !== 'introduced'}
          >
            {t('WITHDRAW_CANDIDATE')}
          </StyledButton>}
          {reject && (
            <CandidateRejectForm
              rejectCandidate={onReject}
              loading={loadingReject}
            />
          )}
        </TabPane>
        <TabPane tab={t('HISTORY')} key="2">
          <HistoryList history={history} />
        </TabPane>
        {current && recruiterFeeSummary?.compensationType && (
          <TabPane tab={t('CANDIDATE_JOB_RECRUITER_FEES')} key="3">
            <Row>
              <Col xs={{ span: 24 }} lg={{ span: 11 }} xl={{ span: 10 }}>
                <InfoItem
                  title={t('CANDIDATE_JOB_RECRUITER_COMPENSATION_TYPE')}
                  result={
                    recruiterFeeSummary.compensationType
                      .charAt(0)
                      .toUpperCase() +
                    recruiterFeeSummary.compensationType.slice(1)
                  }
                />
              </Col>
            </Row>
            <Col xs={{ span: 24 }} lg={{ span: 11 }} xl={{ span: 10 }}>
              <InfoItem
                title={t('CANDIDATE_JOB_RECRUITER_COMPENSATION_TYPE_TOOLTIP')}
                result={recruiterFeeSummary.recruiterFee}
              />
            </Col>
            {!!current?.guarantee_period && (
              <Col xs={{ span: 24 }} lg={{ span: 11 }} xl={{ span: 10 }}>
                <InfoItem
                  title={t('COMPANY_AGREEMENT_GUARANTEE_PERIOD')}
                  result={current?.guarantee_period}
                />
              </Col>
            )}
            {current?.payment_terms && (
              <Col xs={{ span: 24 }} lg={{ span: 11 }} xl={{ span: 10 }}>
                <InfoItem
                  title={t('COMPANY_AGREEMENT_PAYMENT_TERMS')}
                  result={current?.payment_terms}
                />
              </Col>
            )}
            {current?.restitution && (
              <Col xs={{ span: 24 }} lg={{ span: 11 }} xl={{ span: 10 }}>
                <InfoItem
                  title={t('COMPANY_AGREEMENT_RESTITUTION')}
                  result={current?.restitution}
                />
              </Col>
            )}
            {current.restitution_payment_terms && (
              <Col xs={{ span: 24 }} lg={{ span: 11 }} xl={{ span: 10 }}>
                <InfoItem
                  title={t('COMPANY_AGREEMENT_RESTITUTION_PAYMENT_TERMS')}
                  result={current?.restitution_payment_terms}
                />
              </Col>
            )}
            {current.replacement_time && (
              <Col xs={{ span: 24 }} lg={{ span: 11 }} xl={{ span: 10 }}>
                <InfoItem
                  title={t('COMPANY_AGREEMENT_REPLACEMENT_TIME')}
                  result={current?.replacement_time}
                />
              </Col>
            )}
            {(current.exclusivity_for_replacement === true ||
              current.exclusivity_for_replacement === false) && (
                <Col xs={{ span: 24 }} lg={{ span: 11 }} xl={{ span: 10 }}>
                  <InfoItem
                    title={t('COMPANY_AGREEMENT_EXCLUSIVITY_FOR_REPLACEMENT')}
                    result={
                      current?.exclusivity_for_replacement === true
                        ? t('YES')
                        : t('NO')
                    }
                  />
                </Col>
              )}
          </TabPane>
        )}
      </Tab>
    </FormPopUp>
  );
};

export default CandidateHistoryModal;
