import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Header from '../layout/Header';
import Button from '../../UI/buttons/Button';
import { ReactComponent as AddUserIcon } from '../../icons/add-user.svg';
import { useTranslation } from 'react-i18next';
import { LineChart, Line, XAxis, YAxis, ResponsiveContainer } from 'recharts';
import AdminUserAdd from './AdminUserAdd';
import { getAdminMetrics } from '../../api/dashboard';
import { ReactComponent as EmptyIcon } from '../../icons/empty.svg';
import useHirePortTheme from '../../hooks/useHirePortTheme';
import useLocale from '../../hooks/useLocale';

const PageWrapper = styled.div`
  padding: 0 2.5rem 2rem;
`;
const Metrics = styled.div`
  display: inline-grid;
  row-gap: 1rem;
  column-gap: 1rem;
  width: 100%;
`;
const AdminDashboardGrid = styled.div`
  display: inline-grid;
  row-gap: 1rem;
  column-gap: 1rem;
  width: 100%;
  min-height: calc(100vh - 130px);
`;

const TopGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  row-gap: 1rem;
  column-gap: 1rem;
  max-height: calc((100vh - 130px - 3rem) / 3);
  min-height: calc((100vh - 130px - 3rem) / 3);
`;
const MiddleGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  row-gap: 1rem;
  column-gap: 1rem;
  max-height: calc((100vh - 130px - 3rem) / 3);
  min-height: calc((100vh - 130px - 3rem) / 3);
`;
const BottomGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  row-gap: 1rem;
  column-gap: 1rem;
  max-height: calc((100vh - 130px - 3rem) / 3);
  min-height: calc((100vh - 130px - 3rem) / 3);
`;
const Graph = styled.div`
  display: flex;
  flex-direction: column;
  background: #fff;
  border-radius: ${({ theme }) => theme.radius};
  padding: 24px 24px 0;
`;

const GraphHeader = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const GraphTitle = styled.h4`
  font-family: Lato;
  font-size: 10px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: 0.8px;
  text-align: left;
  color: #627d95;
  text-transform: uppercase;
  max-width: 70%;
  position: relative;
  height: 32px;
`;
const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;

const GraphNumber = styled.h5`
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: right;
  color: #061c2e;
  margin-right: 10px;
`;
const Center = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
`;
const StyledContainer = styled(ResponsiveContainer)`
  position: absolute;
`;
const topLevel = [
  {
    title: 'NEW_COMPANIES',
    key: 'clients',
  },
  {
    title: 'NEW_RECRUITERS',
    key: 'recruiters',
  },
  {
    title: 'HIRES_TITLE',
    key: 'hired',
  },
  {
    title: 'NUMBER_OF_RATING',
    key: 'ratings',
  },
];
const middleLevel = [
  {
    title: 'NEW_JOBS',
    key: 'jobs',
  },
  {
    title: 'DASHBOARD_INTRODUCED_CANDIDATES',
    key: 'candidates',
  },
];
const bottomLevel = [
  {
    title: 'OFFER_STAGE',
    key: 'offered',
  },
  {
    title: 'REJECTED_TITLE',
    key: 'rejected',
  },
];
const formattedDate = (value: Date, locale: string) => new Date(value).toLocaleDateString(locale, {
  month: 'numeric',
  day: 'numeric',
});
const Dashboard = () => {
  const { sessionLocale: locale } = useLocale();
  const theme = useHirePortTheme();
  const [addUser, setAddUser] = useState(false);
  const [metrics, setMetrics] = useState<any>(null);
  const { t } = useTranslation();
  useEffect(() => {
    getAdminMetrics().then((res) => setMetrics(res.data));
  }, []);
  let defaultStages = [
    { count: 0, stage: '1st interview', dates: [] },
    { count: 0, stage: '2nd+ interview', dates: [] },
  ];
  if (metrics && metrics.stages.length) {
    defaultStages[0] = metrics.stages[0];
    defaultStages[1].dates = metrics.stages[0].dates.map((date: any) => ({
      date: date.date,
      count: 0,
    }));
  }
  const data = metrics
    ? metrics.stages
      .slice(1, 4)
      .reduce((previousValue: any, currentValue: any) => {
        previousValue[1].count += currentValue.count;
        previousValue[1].dates = previousValue[1].dates.map(
          (date: any, index: number) => {
            return {
              date: date.date,
              count: date.count + currentValue.dates[index].count,
            };
          },
        );
        return previousValue;
      }, defaultStages)
    : defaultStages;

  return (
    <PageWrapper>
      <Header title={t('DASHBOARD_TITLE')}>
        <Button
          type="primary"
          icon={<AddUserIcon />}
          onClick={() => setAddUser(true)}
        >
          {t('ADD_USER_TITLE')}
        </Button>
      </Header>
      {metrics && (
        <Metrics>
          <AdminDashboardGrid>
            <TopGrid>
              {topLevel.map((lev) => {
                return (
                  <Graph key={lev.title}>
                    <GraphHeader>
                      <GraphTitle>{t(lev.title)}</GraphTitle>
                      <GraphNumber>{metrics[lev.key].count}</GraphNumber>
                    </GraphHeader>
                    {metrics[lev.key].count ? (
                      <Wrapper>
                        <StyledContainer debounce={1}>
                          <LineChart data={metrics[lev.key].dates}>
                            {/* <CartesianGrid /> */}
                            <XAxis
                              interval={'preserveStartEnd'}
                              dataKey="date"
                              tick={{
                                fill: '#627d95',
                                fontFamily: 'Lato, sans-serif',
                                fontSize: '10px',
                                color: '#627d95',
                              }}
                              tickSize={-6}
                              tickMargin={15}
                              stroke="#dae5ec"
                              minTickGap={5}
                              tickFormatter={value => formattedDate(value, locale)}
                            />
                            <YAxis
                              allowDecimals={false}
                              orientation="right"
                              tick={{
                                fill: '#627d95',
                                fontFamily: 'Lato, sans-serif',
                                fontSize: '10px',
                                color: '#627d95',
                              }}
                              tickLine={false}
                              axisLine={false}
                              tickSize={-6}
                              tickMargin={15}
                              width={30}
                              stroke="#dae5ec"
                            />
                            <Line
                              type="monotone"
                              dot={false}
                              dataKey="count"
                              stroke={theme.primaryColor}
                              strokeWidth={3}
                            />
                          </LineChart>
                        </StyledContainer>
                      </Wrapper>
                    ) : (
                      <Center>
                        {' '}
                        <EmptyIcon />
                      </Center>
                    )}
                  </Graph>
                );
              })}
            </TopGrid>
            <MiddleGrid>
              {middleLevel.map((lev) => {
                return (
                  <Graph key={lev.title}>
                    <GraphHeader>
                      <GraphTitle>{t(lev.title)}</GraphTitle>
                      <GraphNumber>{metrics[lev.key].count}</GraphNumber>
                    </GraphHeader>
                    {metrics[lev.key].count ? (
                      <Wrapper>
                        <StyledContainer debounce={1}>
                          <LineChart data={metrics[lev.key].dates}>
                            {/* <CartesianGrid /> */}
                            <XAxis
                              interval={'preserveStartEnd'}
                              dataKey="date"
                              tick={{
                                fill: '#627d95',
                                fontFamily: 'Lato, sans-serif',
                                fontSize: '10px',
                                color: '#627d95',
                              }}
                              tickSize={-6}
                              tickMargin={15}
                              stroke="#dae5ec"
                              minTickGap={5}
                              tickFormatter={value => formattedDate(value, locale)}
                            />
                            <YAxis
                              allowDecimals={false}
                              orientation="right"
                              tick={{
                                fill: '#627d95',
                                fontFamily: 'Lato, sans-serif',
                                fontSize: '10px',
                                color: '#627d95',
                              }}
                              tickLine={false}
                              axisLine={false}
                              tickSize={-6}
                              tickMargin={15}
                              width={30}
                              stroke="#dae5ec"
                            />
                            <Line
                              type="monotone"
                              dot={false}
                              dataKey="count"
                              stroke={theme.primaryColor}
                              strokeWidth={3}
                            />
                          </LineChart>
                        </StyledContainer>
                      </Wrapper>
                    ) : (
                      <Center>
                        {' '}
                        <EmptyIcon />
                      </Center>
                    )}
                  </Graph>
                );
              })}
            </MiddleGrid>
            <BottomGrid>
              <Graph key={'INTRODUCED'}>
                <GraphHeader>
                  <GraphTitle>
                    {t('INTRODUCED') + ' ' + t('CANDIDATES')}
                  </GraphTitle>
                  <GraphNumber>{metrics['introduced'].count}</GraphNumber>
                </GraphHeader>
                {metrics['introduced'].count ? (
                  <Wrapper>
                    <StyledContainer debounce={1}>
                      <LineChart data={metrics['introduced'].dates}>
                        {/* <CartesianGrid /> */}
                        <XAxis
                          interval={'preserveStartEnd'}
                          dataKey="date"
                          tick={{
                            fill: '#627d95',
                            fontFamily: 'Lato, sans-serif',
                            fontSize: '10px',
                            color: '#627d95',
                          }}
                          tickSize={-6}
                          tickMargin={15}
                          stroke="#dae5ec"
                          minTickGap={5}
                        />
                        <YAxis
                          allowDecimals={false}
                          orientation="right"
                          tick={{
                            fill: '#627d95',
                            fontFamily: 'Lato, sans-serif',
                            fontSize: '10px',
                            color: '#627d95',
                          }}
                          tickLine={false}
                          axisLine={false}
                          tickSize={-6}
                          tickMargin={15}
                          width={30}
                          stroke="#dae5ec"
                        />
                        <Line
                          type="monotone"
                          dot={false}
                          dataKey="count"
                          stroke={theme.primaryColor}
                          strokeWidth={3}
                        />
                      </LineChart>
                    </StyledContainer>
                  </Wrapper>
                ) : (
                  <Center>
                    <EmptyIcon />
                  </Center>
                )}
              </Graph>
              {data.map((stage: any) => {
                return (
                  <Graph key={stage.stage}>
                    <GraphHeader>
                      <GraphTitle>
                        {t('CANDIDATES_ON', { stage: stage.stage })}
                      </GraphTitle>
                      <GraphNumber>{stage.count}</GraphNumber>
                    </GraphHeader>
                    {stage.count ? (
                      <Wrapper>
                        <StyledContainer>
                          <LineChart data={stage.dates}>
                            <XAxis
                              interval={'preserveStartEnd'}
                              dataKey="date"
                              tick={{
                                fill: '#627d95',
                                fontFamily: 'Lato, sans-serif',
                                fontSize: '10px',
                                color: '#627d95',
                              }}
                              tickSize={-6}
                              tickMargin={15}
                              stroke="#dae5ec"
                              minTickGap={5}
                            />
                            <YAxis
                              allowDecimals={false}
                              orientation="right"
                              tick={{
                                fill: '#627d95',
                                fontFamily: 'Lato, sans-serif',
                                fontSize: '10px',
                                color: '#627d95',
                              }}
                              tickLine={false}
                              axisLine={false}
                              tickSize={-6}
                              tickMargin={15}
                              width={30}
                              stroke="#dae5ec"
                            />
                            <Line
                              type="monotone"
                              dot={false}
                              dataKey="count"
                              stroke={theme.primaryColor}
                              strokeWidth={3}
                            />
                          </LineChart>
                        </StyledContainer>
                      </Wrapper>
                    ) : (
                      <Center>
                        <EmptyIcon />
                      </Center>
                    )}
                  </Graph>
                );
              })}
              {bottomLevel.map((lev) => {
                return (
                  <Graph key={lev.title}>
                    <GraphHeader>
                      <GraphTitle>
                        {lev.key !== 'offered'
                          ? t(lev.title) + ' ' + t('CANDIDATES')
                          : t('CANDIDATES_ON', { stage: t('OFFER') })}
                      </GraphTitle>
                      <GraphNumber>{metrics[lev.key].count}</GraphNumber>
                    </GraphHeader>
                    {metrics[lev.key].count ? (
                      <Wrapper>
                        <StyledContainer debounce={1}>
                          <LineChart data={metrics[lev.key].dates}>
                            {/* <CartesianGrid /> */}
                            <XAxis
                              interval={'preserveStartEnd'}
                              dataKey="date"
                              tick={{
                                fill: '#627d95',
                                fontFamily: 'Lato, sans-serif',
                                fontSize: '10px',
                                color: '#627d95',
                              }}
                              tickSize={-6}
                              tickMargin={15}
                              stroke="#dae5ec"
                              minTickGap={5}
                            />
                            <YAxis
                              allowDecimals={false}
                              orientation="right"
                              tick={{
                                fill: '#627d95',
                                fontFamily: 'Lato, sans-serif',
                                fontSize: '10px',
                                color: '#627d95',
                              }}
                              tickLine={false}
                              axisLine={false}
                              tickSize={-6}
                              tickMargin={15}
                              width={30}
                              stroke="#dae5ec"
                            />
                            <Line
                              type="monotone"
                              dot={false}
                              dataKey="count"
                              stroke={theme.primaryColor}
                              strokeWidth={3}
                            />
                          </LineChart>
                        </StyledContainer>
                      </Wrapper>
                    ) : (
                      <Center>
                        <EmptyIcon />
                      </Center>
                    )}
                  </Graph>
                );
              })}
            </BottomGrid>
          </AdminDashboardGrid>
        </Metrics>
      )}

      <AdminUserAdd visible={addUser} setVisible={setAddUser} />
    </PageWrapper>
  );
};

export default Dashboard;
