/* Interview stage statuses*/

export const PLANNING = 'planning';
export const WAITING_RECRUITER = 'waiting for recruiter';
export const WAITING_CLIENT = 'waiting for client';
export const IN_PROGRESS = 'in progress';
export const COMPLETED = 'completed';

export const SOURCED = 'sourced';
export const INTRODUCED = 'introduced';
export const IN_PROCESS = 'in process';
export const HIRED = 'hired';
export const OFFERED = 'offered';
export const REJECTED = 'rejected';
export const ON_HOLD = 'on hold';
export const POSITIVE = 'positive';


export const WAITING_RECRUITER_OFFER = 'waiting_for_recruiter';
export const WAITING_CLIENT_OFFER = 'waiting_for_client';

export const HIRED_NAME = 'Hired';
export const REJECTED_NAME = 'Rejected';
