import styled from 'styled-components';
import { Badge } from 'antd';
import { ReactComponent as HeartIcon } from '../../../icons/heart-centered.svg';
import { ReactComponent as ArchiveIconOriginal } from '../../../icons/icon-archive.svg';

export const FavoriteIcon = styled(HeartIcon)<{ favorite: boolean }>`
  margin-left: 0.25rem;
  color: ${(props) => (props.favorite ? '#34ce72' : '#627D95')};
  fill: ${(props) => (props.favorite ? '#34ce72' : 'transparent')};
  cursor: pointer;
`;

export const ArchiveIcon = styled(ArchiveIconOriginal)`
  margin-left: 0.25rem;
  color: #aebeca;
  cursor: pointer;
  &.active {
    color: #34ce72;
    fill: #34ce72;
  }
`;

export const PageWrapper = styled.div`
  padding: 0 2.5rem 2rem;
  @media (max-height: 740px) {
    padding: 0 2.5rem 1rem;
  }
`;

export const DashboardGrid = styled.div`
    display: grid;
  grid-gap: 16px;
  grid-template-columns: 100%;
  //flex-direction: column;
  grid-template-rows: 144px minmax(50px, 550px) minmax(252px, 255px);
  height: calc(100vh - 115px);
  @media (max-height: 740px) {
    height: calc(100vh - 95px);
    grid-template-rows: 128px minmax(50px, 550px) minmax(240px, 240px);
  }
  @media (min-width: 2100px) {
    height: calc(100vh - 115px);
    grid-template-rows: 150px minmax(50px, 100%) minmax(240px, 252px);
  }
`;

export const DashboardTopBlock = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 1rem;
`;

export const StatisticsWrapper = styled.div`
  display: grid;
  width: calc(100vw - 528px);
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(2, 1fr);
  row-gap: 1rem;
  column-gap: 1rem;
`;

export const HelloBlock = styled.div`
  width: 368px;
  height: 144px;
  margin-right: 1rem;
  flex-grow: 0;
  padding: 24px 40px 48px 32px;
  border-radius: 8px;
  background-color: #34ce72;
  position: relative;
  @media (max-height: 740px) {
    height: 128px;
  }
`;

export const HelloBlockTitle = styled.h2`
  margin: 0 0 8px;
  font-family: Lato;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: left;
  color: #ffffff;
  max-width: 75%;
`;

export const HelloBlockDescription = styled.p`
  font-family: Lato;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: left;
  color: #ffffff;
  max-width: 78%;
  margin: 0;
`;

export const HelloBlockImage = styled.img`
  position: absolute;
  right: .5rem;
  bottom: .5rem;
`;

export const StatisticsItem = styled.div<{ stages?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1.5rem;
  border-radius: 8px;
  background-color: #ffffff;
  //height: ${(props) => (props.stages ? 'auto' : '4rem')};
  max-height: ${(props) => (props.stages ? '9rem' : 'inherit')};
  cursor: pointer;
  height: 4rem;
  // grid-row-start: ${(props) => (props.stages ? '1' : 'inherit')};
  // grid-row-end: ${(props) => (props.stages ? 'span2' : 'inherit')};
  // grid-column-start: ${(props) => (props.stages ? '4' : 'inherit')};
  // grid-column-end: ${(props) => (props.stages ? '4' : 'inherit')};
  @media  (max-height:740px){
    height: 3.5rem;
  }
`;

export const StatisticsItemStages = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
`;

export const StatisticsItemStage = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
`;

export const StatisticsItemTitle = styled.h4`
  font-family: Lato;
  font-size: 10px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: 0.8px;
  text-align: left;
  color: #627d95;
  max-width: 60%;
  text-transform: uppercase;
  position: relative;
  margin: 0;
`;

export const StatisticsItemValue = styled.h5`
  font-family: Lato;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: right;
  color: #061c2e;
  margin-bottom: 0;
`;

export const StatisticsItemValueBadge = styled(Badge)`
  & .ant-badge-count {
    top: 0;
    right: -4px;
    min-width: 1rem;
    height: 1rem;
    padding: 0;
    line-height: 1rem;
    background: #34ce72;
  }
`;

export const DashboardMiddleBlock = styled.div`
  height: 100%;
  //min-height: 252px;
  margin: 0 0 1rem;
`;

export const DashboardBottomBlock = styled.div`
  display: grid;
  grid-template-columns: 272px auto;
  grid-template-rows: auto;
  column-gap: 1rem;
  max-height: 252px;
  @media (min-width: 2100px) {
      max-height: initial;
  }
`;

export const CandidatesListItem = styled.li`
  display: grid;
  grid-template-columns: 200px 200px auto 160px;
  column-gap: 2rem;
  align-items: center;
  justify-content: space-between;
  padding: 12px 0;
  border-bottom: 1px solid #dae5ec;
  position: relative;
  cursor: pointer;
`;

export const CandidateNameContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const CandidateName = styled.h6`
  font-family: Lato;
  font-size: 14px;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: left;
  color: #061c2e;
  margin-left: 0.5rem;
  margin-bottom: 0;
`;

export const CandidateInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const CandidateCompanyName = styled.p`
  font-family: Lato;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: left;
  color: #061c2e;
  margin: 0 0 4px 0;
  position: relative;
`;

export const CandidatePosition = styled.p`
  font-family: Lato;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: left;
  color: #627d95;
  margin: 0;
`;

export const CandidateStageProgressContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const CandidateStage = styled.div`
  background: #f2f6fa;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 128px;
  padding: 4px 8px;
  font-family: Lato;
  font-size: 10px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: 0.8px;
  text-align: center;
  color: #061c2e;
  text-transform: uppercase;
  margin-bottom: 8px;
`;

export const CandidateStageProgress = styled.span`
  display: flex;
  width: 128px;
  height: 4px;
  border-radius: 4px;
  background: #f2f6fa;
`;

export const CandidateStageProgressBar = styled.span<{ progress: number }>`
  width: ${(props) => props.progress + '%'};
  background-color: #aebeca;
`;

export const CandidateActionContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 40px;
`;

export const CandidateActionName = styled.p`
  font-family: Lato;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: left;
  color: #061c2e;
  margin-bottom: 4px;
`;

export const CandidateActionDateRelative = styled.span`
  text-transform: capitalize;
`;

export const CandidateActionDate = styled.p<{ isExpired: boolean }>`
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: left;
  color: ${(props) => (props.isExpired ? '#eb5757' : '#627d95')};
  margin: 0;
`;

export const CandidateActionIcon = styled.img`
  position: absolute;
  right: 0;
`;

export const JobListItem = styled.li`
  display: grid;
  grid-template-columns: minmax(230px,40%) minmax(200px,25%) 152px 168px 140px auto;
  column-gap: 1rem;
  align-items: center;
  justify-content: space-between;
  padding: 11px 0;
  border-bottom: 1px solid #dae5ec;
  position: relative;
  cursor: pointer;
`;

export const JobTitle = styled.h4`
  font-family: Lato;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: left;
  color: #061c2e;
  margin: 0;
`;

export const JobCompanyName = styled.p`
  font-family: Lato;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: left;
  color: #061c2e;
  margin: 0;
  display: flex;
  align-items: center;
`;

export const JobSalary = styled.p`
  font-family: Lato;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: left;
  color: #061c2e;
  margin: 0;
`;

export const JobLocation = styled.p`
  font-family: Lato;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: left;
  color: #061c2e;
  margin: 0;
`;

export const JobWorkingType = styled.p`
  font-family: Lato;
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.8px;
  text-align: left;
  color: #627d95;
  margin: 4px 0 0 0;
  text-transform: uppercase;
`;

export const JobBonus = styled.span`
  font-family: Lato;
  font-size: 10px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: 0.8px;
  text-align: center;
  text-transform: uppercase;
  color: #061c2e;
  height: 24px;
  flex-grow: 0;
  padding: 4px 8px;
  border-radius: 4px;
  background-color: #eff9f3;
  white-space: nowrap;
`;

export const JobActions = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const Image = styled.div`
  width: 1.5rem;
  height: 1.5rem;
  min-width: 1.5rem;
  min-height: 1.5rem;
  border: solid 1px #dae5ec;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 0.5rem;
  & img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

export const EmptyText = styled.p`
  font-family: Lato;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: center;
  color: #aebeca;
  padding: 5rem 0;
`;
