import React, { useEffect, useState } from 'react';
import { getRecruiterCompanyAgreements } from '../../api/recruiter';
import { Job, Language, CompanyAgreement } from '../../types';
import RecruiterJobListItem from '../jobs/jobList/RecruiterJobListItem';
interface JobProps {
  jobs: Job[];
  languages: Language[];
  setJobs: React.Dispatch<React.SetStateAction<Job[]>>;
}

function appendAgreementToJobs(
  jobs: Job[],
  agreementCompanies: CompanyAgreement[],
) {
  for (let i = 0; i < jobs.length; i++) {
    for (let j = 0; j < agreementCompanies.length; j++) {
      if (agreementCompanies[j].company === jobs[i].company.id) {
        jobs[i]['company_agreement'] = agreementCompanies[j];
      }
    }
  }

  return jobs;
}
const RecruiterJobs = ({ jobs, setJobs, languages }: JobProps) => {
  const [updatedJobs, setUpdatedJobs] = useState<any>([]);
  useEffect(() => {
    getRecruiterCompanyAgreements().then((res) => {
      let updatedJobs = appendAgreementToJobs(jobs, res.data);
      setUpdatedJobs(updatedJobs);
    });
  }, [jobs]);

  return (
    <div>
      {updatedJobs.map((job: any) => (
        <RecruiterJobListItem
          job={job}
          key={job.id}
          setJobs={setJobs}
          languages={languages}
        />
      ))}
    </div>
  );
};

export default RecruiterJobs;
