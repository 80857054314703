import React, { useEffect, useState } from 'react';
import { Col, DatePicker, Form, Row } from 'antd';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import FormItem from '../../form/FormItem';
import moment from 'moment';
import Input from '../../../UI/inputs/Input';

const FilterWrapper = styled.div`
  padding: 1rem 2rem 1rem 2rem;
  border-radius: ${({ theme }) => theme.radius};
  background: #ffffff;
  margin-bottom: 1rem;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 998;
  width: 100%;
  margin-left: 0;
  margin-right: 0;
  @media (max-width: 576px) {
    position: relative;
  }
`;

// Custom styled DatePicker to match the Input component styling
const StyledDatePicker = styled(DatePicker)`
  &.ant-picker {
    width: 100%;
    border-radius: ${({ theme }) => theme.radius};
    border: 1px solid #aebeca;
    height: 48px; /* Fixed height to match Input component */
  }
  
  &.ant-picker-lg {
    padding: 0.813rem 1rem;
    line-height: 1.43;
    font-size: 0.875rem;
    color: #061c2e;
  }
  
  .ant-picker-input {
    height: 100%;
    display: flex;
    align-items: center;
  }
  
  &.ant-picker:hover {
    border: solid 1px ${({ theme }) => theme.hoverColor};
  }
  
  &.ant-picker-focused {
    box-shadow: 0 0 8px 0 ${({ theme }) => theme.primaryColor};
    border-color: transparent;
  }
  
  .ant-picker-input > input::placeholder {
    color: #aebeca;
  }

  /* Make sure the DatePicker's inner content is vertically centered */
  .ant-picker-input > input {
    height: 100%;
  }
`;

const KanbanFilter = () => {
  const history = useHistory();
  const params = new URLSearchParams(history.location.search);
  const [t] = useTranslation();
  const [form] = Form.useForm();

  const [data, setData] = useState<{
    job_title: string | null;
    company_name: string | null;
    last_update_before: string | null;
    last_update_after: string | null;
  }>({
    job_title: params.get('job_title'),
    company_name: params.get('company_name'),
    last_update_before: params.get('last_update_before'),
    last_update_after: params.get('last_update_after'),
  });

  const applyFilters = (values: any) => {
    // Preserve the filter parameter that determines the Kanban view
    const filterParam = params.get('filter');
    
    // Clear all filter parameters first (except filter)
    Array.from(params.keys()).forEach(key => {
      if (key !== 'filter') {
        params.delete(key);
      }
    });
    
    // Add back the filter parameters from the form
    Object.keys(values).forEach((key) => {
      if (values[key]) {
        if (key === 'last_update_before' || key === 'last_update_after') {
          // Format date values to ISO string for URL params
          if (values[key] && values[key]._isAMomentObject) {
            // Format to YYYY-MM-DD to ensure consistent date handling
            const formattedDate = values[key].format('YYYY-MM-DD');
            params.set(key, formattedDate);
          }
        } else {
          params.set(key, values[key]);
        }
      }
    });
    
    // Make sure the filter parameter is preserved
    if (filterParam) {
      params.set('filter', filterParam);
    }
    
    // Push the new URL
    history.push({ search: params.toString() });
  };

  useEffect(() => {
    form.setFieldsValue({
      job_title: data.job_title,
      company_name: data.company_name,
      last_update_before: data.last_update_before ? moment(data.last_update_before) : null,
      last_update_after: data.last_update_after ? moment(data.last_update_after) : null,
    });
  }, [form, data]);

  return (
    <div>
      <FilterWrapper>
        <Form
          scrollToFirstError={true}
          layout="vertical"
          name="kanban-filter"
          form={form}
          initialValues={{
            job_title: data.job_title,
            company_name: data.company_name,
            last_update_before: data.last_update_before ? moment(data.last_update_before) : null,
            last_update_after: data.last_update_after ? moment(data.last_update_after) : null,
          }}
          onValuesChange={(changedValues, allValues) => {
            setData(prev => ({ ...prev, ...changedValues }));
            
            // For date fields, we need to delay the application slightly
            // to avoid issues with the DatePicker component's internal state
            if (changedValues.last_update_before !== undefined || changedValues.last_update_after !== undefined) {
              setTimeout(() => {
                applyFilters(allValues);
              }, 100);
            } else {
              // For text fields, apply immediately
              applyFilters(allValues);
            }
          }}
        >
          <Row gutter={16}>
            <Col xs={{ span: 24 }} md={{ span: 12 }} xl={{ span: 6 }}>
              <FormItem label={t('JOB_TITLE')} name="job_title">
                <Input placeholder={t('JOB_TITLE_PLACEHOLDER')} size="large" />
              </FormItem>
            </Col>
            
            <Col xs={{ span: 24 }} md={{ span: 12 }} xl={{ span: 6 }}>
              <FormItem label={t('COMPANY_NAME')} name="company_name">
                <Input placeholder={t('COMPANY_NAME_PLACEHOLDER')} size="large" />
              </FormItem>
            </Col>
            
            <Col xs={{ span: 24 }} md={{ span: 12 }} xl={{ span: 6 }}>
              <FormItem label={t('LAST_UPDATE_AFTER')} name="last_update_after">
                <StyledDatePicker 
                  placeholder={t('SELECT_DATE')} 
                  size="large"
                  format="YYYY-MM-DD"
                />
              </FormItem>
            </Col>
            
            <Col xs={{ span: 24 }} md={{ span: 12 }} xl={{ span: 6 }}>
              <FormItem label={t('LAST_UPDATE_BEFORE')} name="last_update_before">
                <StyledDatePicker 
                  placeholder={t('SELECT_DATE')} 
                  size="large"
                  format="YYYY-MM-DD"
                />
              </FormItem>
            </Col>
          </Row>
        </Form>
      </FilterWrapper>
    </div>
  );
};

export default KanbanFilter; 