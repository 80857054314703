import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Col, Form, Row, Select } from 'antd';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
//import uniqBy from 'lodash/uniqBy';

import FormItem from '../../form/FormItem';
import { getSpecializationList } from '../../../api/specialization';
import { getSeniorityList } from '../../../api/seniority';
import { getCityList } from '../../../api/regions';
import { getSkillsList } from '../../../api/skills';
import { City, Seniority, Skill, Specialization } from '../../../types';
import { getCompanyByName, getCompanyDepartments } from '../../../api/company';
import { useAppSelector } from '../../../store/hooks';
import {
  ADMIN_ROLE,
  CLIENT_ROLE,
  RECRUITER_ROLE,
} from '../../../constants/roles';
import JobFilter from '../../form/JobFilter';
import SelectInput from '../../../UI/inputs/Select';
import { ReactComponent as ArrowDownIcon } from '../../../icons/arrow-down.svg';
import { debounce } from 'lodash';

const FilterWrapper = styled.div`
  padding: 1rem 2rem 0;
  border-radius: ${({ theme }) => theme.radius};
  background: #ffffff;
  margin-bottom: 1rem;
  position: sticky;
  top: 0;
  z-index: 998;
  @media (max-width: 576px) {
    position: relative;
  }
`;

interface CompanyProps {
  id: string;
  name: string;
}

const { Option } = Select;
const JobListFilter = () => {
  const history = useHistory();
  const params = new URLSearchParams(history.location.search);
  const [data, setData] = useState<{
    domain: Specialization[];
    seniority: Seniority[];
    cities: City[];
    skills: Skill[];
    salary_from: null | string;
    salary_to: null | string;
    not: boolean;
    logical: null | string;
    company_name: [];
    visa: boolean[];
    language: [];
    relocation: string;
  }>({
    domain: [],
    seniority: [],
    cities: [],
    skills: [],
    company_name: [],
    visa: [true, false],
    language: [],
    relocation: '0',
    salary_from: null,
    salary_to: null,
    logical: params.get('skill')?.includes('OR') ? 'OR' : 'AND',
    not: !!params.get('skill')?.includes('NOT'),
  });

  const [companies, setCompanies] = useState<CompanyProps[]>([]);
  const [departments, setDepartments] = useState<string[]>([]);

  const handleSearch = async (value: string) => {
    await getCompanyByName(value).then((res) => setCompanies(res.data));
  };

  const { user } = useAppSelector((state) => state.user);
  const isAdmin = user?.role === ADMIN_ROLE;
  const isRecruiter = user?.role === RECRUITER_ROLE;
  const isClient = user?.role === CLIENT_ROLE;

  const [t] = useTranslation();
  const [form] = Form.useForm();

  const getClientByCompany = useCallback(async (id?: string) => {
    return (
      id &&
      (await getCompanyDepartments(id).then((res) => {
        setDepartments(res.data.departments ? res.data.departments : []);
      }))
    );
  }, []);

  useEffect(() => {
    const query = new URLSearchParams(history.location.search);
    const company = query.get('company');

    if (isAdmin) {
      if (company) {
        getClientByCompany(company).then(() => {
          form.setFieldsValue({
            department: query.get('department'),
          });
        });
        handleSearch('').then(() => {
          form.setFieldsValue({ company });
        });
      } else {
        getClientByCompany().then(() => {
          form.setFieldsValue({
            department: query.get('department'),
          });
        });
      }
    }
  }, [form, isAdmin, getClientByCompany, history]);

  const onFinish = (values: any) => {
    console.log('Form submitted with values:', values);  // Debug log
    Object.keys(values).forEach((key) => {
      if (values[key]) {
        if (key === 'skill') {
          if (
            !values[key].length ||
            (values[key].length && values[key][0] === '')
          ) {
            params.delete(key);
          } else {
            params.set(
              key,
              values.not
                ? 'NOT ' +
                values[key].join(
                  ` ${values.logical} ${values.not ? 'NOT' : ''} `,
                )
                : values[key].join(` ${values.logical} `),
            );
          }
        } else {
          params.set(key, values[key]);
        }
      } else {
        params.delete(key);
      }
    });
    history.push({ search: params.toString() });
  };

  useEffect(() => {
    const query = new URLSearchParams(history.location.search);
    form.setFieldsValue({
      logical: query.get('skill')?.includes('OR') ? 'OR' : 'AND',
      not: !!query.get('skill')?.includes('NOT'),
      search: query.get('search'),
      skill: !!data.skills.length
        ? query
          .get('skill')
          ?.replace(/OR|NOT|AND|[+]/g, '')
          .split(' ')
          .filter((el) => el)
        : [],
      //domain: !!data.domain.length && query.get('domain'),
      seniority: !!data.seniority.length && query.get('seniority'),
      location: !!data.cities.length && query.get('location'),
      salary_from: query.get('salary_from'),
      salary_to: query.get('salary_to'),
      job_status: query.get('job_status'),
    });
  }, [form, history.location.search, isAdmin, data]);

  useEffect(() => {
    const query = new URLSearchParams(history.location.search);
    axios
      .all([
        getSpecializationList(),
        getSeniorityList(),
        getCityList(),
        getSkillsList(),
      ])
      .then((res) => {
        //const hiddentSpecs = res[0]?.data.filter((s: any) => s.is_hidden === true);
        const userSpecs = user?.specializations || [];
        //const recruiterDomains = uniqBy([...hiddentSpecs, ...userSpecs], 'id');
        const recruiterDomains = userSpecs;
        let mappedDomains: any[] = [];

        for (let i = 0; i < recruiterDomains.length; ++i) {
          let copiedDomain = JSON.parse(JSON.stringify(recruiterDomains[i]));
          // @ts-ignore
          let subdomains = copiedDomain.sub_domains;
          if (subdomains.length) {
            // @ts-ignore
            for (let j = 0; j < subdomains.length; ++j) {
              let subdomain = subdomains[j];
              if (!recruiterDomains.find((el: any) => el.id === subdomain.id)) {
                // @ts-ignore
                copiedDomain.sub_domains.splice(j, 1);
              }
            }
          }
          mappedDomains.push(copiedDomain);
        }

        setData((prev) => ({
          ...prev,
          domain: isRecruiter ? mappedDomains : res[0].data,
          seniority: isRecruiter ? user?.seniority : res[1].data,
          cities: res[2].data,
          skills: res[3].data,
          salary_from: query.get('salary_from')
            ? query.get('salary_from')
            : null,
          salary_to: query.get('salary_from') ? query.get('salary_to') : null,
        }));
      });
  }, [history.location.search, isRecruiter, user]);

  const handleClear = () => {
    form.setFieldsValue({
      department: undefined,
    });
    setDepartments([]);
  };

  return (
    <FilterWrapper>
      <Form
        scrollToFirstError={true}
        layout="vertical"
        name="filter"
        onFinish={onFinish}
        form={form}
        onValuesChange={debounce((changedValues, allValues) => { onFinish(allValues); }, 300)}
      >
        <Row gutter={16}>
          {isAdmin && (
            <>
              <Col xs={{ span: 12 }} md={{ span: 8 }} xl={{ span: 6 }}>
                <FormItem label={t('COMPANY_TITLE')} name="company">
                  <SelectInput
                    showSearch
                    placeholder={t('COMPANY_NAME_TITLE')}
                    allowClear
                    className="custom-select"
                    size="large"
                    defaultActiveFirstOption={false}
                    showArrow={false}
                    filterOption={false}
                    onSearch={handleSearch}
                    onClear={handleClear}
                    notFoundContent={null}
                    onSelect={(e) => {
                      getClientByCompany(e).then(() => {
                        form.setFieldsValue({ department: undefined });
                      });
                    }}
                  >
                    {companies.map((company: CompanyProps) => (
                      <Option key={company.id} value={company.id}>
                        {company.name}
                      </Option>
                    ))}
                  </SelectInput>
                </FormItem>
              </Col>
              <Col xs={{ span: 12 }} md={{ span: 8 }} xl={{ span: 6 }}>
                <FormItem label={t('DEPARTMENT_TITLE')} name="department">
                  <SelectInput
                    showSearch
                    placeholder={t('DEPARTMENT_TITLE')}
                    optionFilterProp="children"
                    filterOption={true}
                    className="custom-select"
                    suffixIcon={<ArrowDownIcon />}
                    size="large"
                    defaultActiveFirstOption={false}
                    notFoundContent={null}
                  >
                    {!form.getFieldValue('company') ? (
                      <Option disabled value="">
                        {t('DEPARTMENT_PLACEHOLDER')}
                      </Option>
                    ) : (
                      <Option value="">{t('NONE')}</Option>
                    )}
                    {departments.map((department: string) => (
                      <Option key={department} value={department}>
                        {department}
                      </Option>
                    ))}
                  </SelectInput>
                </FormItem>
              </Col>
            </>
          )}
          <JobFilter
            show={isAdmin || isClient}
            data={data}
            isClient={isClient}
            isRecruiter={isRecruiter}
          />
        </Row>
        {/* Magnifying glass button commented out since we now use auto-filtering
        <StyledButton type="submit">
          <SearchIcon />
        </StyledButton>
        */}
      </Form>
    </FilterWrapper>
  );
};

export default JobListFilter;
