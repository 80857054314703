import { List } from 'antd';
import React, { useState, useImperativeHandle, forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import Loader from '../../UI/Loader'
import Subtitle from '../../UI/typography/Subtitle';
import Text from '../../UI/typography/Text';
import { ReactComponent as SuccessIcon } from '../../icons/check-icon-brand-inverted.svg';
import { ReactComponent as ErrorIcon } from '../../icons/icon-close-danger-inverted.svg';
import type { CandidateInfo } from '../../types';

type BulkRecapItemBase = { cv: string };
type BulkRecapSucceededItem = BulkRecapItemBase & {
  success: true;
  data: Partial<CandidateInfo>;
};
type BulkRecapErroredItem = BulkRecapItemBase & {
  success: false;
  error_code: string;
  error_detail?: string;
};
export type BulkRecapItem = BulkRecapItemBase | BulkRecapSucceededItem | BulkRecapErroredItem;

function isSuccess(item: BulkRecapItem): item is BulkRecapSucceededItem {
  return (item as BulkRecapSucceededItem).success;
}

function isErrored(item: BulkRecapItem): item is BulkRecapErroredItem {
  return (item as BulkRecapErroredItem).success === false;
}

export interface BulkUploadRecapProps {
  onFilesDrop?: (file: BulkRecapItem[]) => void;
  onFileUploaded?: (file: BulkRecapItem) => void;
}

const BulkUploadRecap = forwardRef((_, ref) => {
  const [t] = useTranslation();
  const [uploadedFiles, setUploadedFiles] = useState<BulkRecapItem[]>([]);

  useImperativeHandle(ref, () => ({
    onFileUploaded(file: BulkRecapItem) {
      setUploadedFiles(prevFiles => prevFiles.map(f => (f.cv === file.cv ? { ...f, ...file } : f))
      );
    },
    onFilesDrop(files: BulkRecapItem[]) {
      setUploadedFiles(files);
    }
  }));

  return (
    <>
      {uploadedFiles.length > 0 &&
        <>
          <Subtitle title={t('RECRUITER_BULK_UPLOAD_RECAP_TITLE')} marginBottom="0px" />
          <Text text={t('RECRUITER_BULK_UPLOAD_RECAP_DESCRIPTION')} />
          <List
            itemLayout="horizontal"
            bordered
            dataSource={uploadedFiles}
            renderItem={(item, index) =>
              <List.Item key={index}>
                <List.Item.Meta
                  avatar={
                    isSuccess(item)
                      ? <SuccessIcon />
                      : isErrored(item) ? <ErrorIcon />
                        : <Loader spinning size='small' />
                  }
                  title={item.cv}
                  description={
                    (isSuccess(item)
                      ? [item.data.name, item.data.current_role].join(', ')
                      : isErrored(item) ? [t(`RECRUITER_BULK_UPLOAD_ERROR_${item.error_code.toUpperCase()}`), item.error_detail].filter((str) => !!str).join(': ') : ''
                    )
                  }
                />
              </List.Item>
            }
          />
        </>
      }
    </>
  )
});

export default BulkUploadRecap;
