import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import en from './locales/hireport-en.json';
import nl from './locales/hireport-nl.json';
import fr from './locales/hireport-fr.json';
import de from './locales/hireport-de.json';
import es from './locales/hireport-es.json';
import it from './locales/hireport-it.json';
import { getBrowserLocale } from '../hooks/useLocale';

const lng = getBrowserLocale();
export default i18n.use(initReactI18next).init({
  resources: {
    en: { translation: en },
    nl: { translation: nl },
    fr: { translation: fr },
    de: { translation: de },
    it: { translation: it },
    es: { translation: es },
  },
  lng,
  fallbackLng: 'en',
  debug: process.env.NODE_ENV === 'development',
});
